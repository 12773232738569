interface IBlock {
  [key: string]: string | JSX.Element;
}

interface IThreeBlockSectionProps {
  blocks: IBlock[];
}

export default function ThreeBlocksSection({
  blocks,
}: IThreeBlockSectionProps) {
  const getKey = (block: IBlock) => {
    if (typeof block.title === "string") {
      return block.title;
    }
    if (typeof block.body === "string") {
      return block.body;
    }
  };

  return (
    <div className="flex flex-col gap-4 lg:flex-row lg:gap-8">
      {blocks.map((block) => (
        <div
          key={getKey(block)}
          className="flex flex-1 flex-col items-center justify-center gap-4 rounded-[40px] bg-white/[.06] px-12 py-8 shadow-[0px_0px_80px_0px_#001B36] lg:justify-start lg:px-16 lg:py-12"
        >
          <div className="flex lg:min-h-[80px] lg:items-center">
            <h3 className="text-center text-2xl text-white lg:text-3xl">
              {block.title}
            </h3>
          </div>
          <div className="flex lg:items-start">
            <p className="m-0 text-center text-xl font-normal text-indigo-200">
              {block.body}
            </p>
          </div>
        </div>
      ))}
    </div>
  );
}
