import SectionHeader from "../common/SectionHeader";
import ThreeStepsSection from "../common/ThreeStepsSection";

const steps = [
  {
    title: "Visit our Kickstarter Page",
    body: "Interested in supporting our mission? Click on any “Visit Kickstarter” button on this page to visit our project.",
  },
  {
    title: "Choose a Support Package",
    body: "Every little bit helps. Whatever is in your heart to give, we thank you for your contribution.",
  },
  {
    title: "Share and Support",
    body: "Share with your friends, family members, parents, grandparents, and anyone else who you think would find joy in supporting our mission.",
  },
];

export default function HowItWorks() {
  return (
    <div className="flex flex-col gap-6 lg:gap-20">
      <SectionHeader
        headerType="h2"
        headerText="How It Works"
        starLocation="right"
      />
      <ThreeStepsSection steps={steps} />
    </div>
  );
}
