import { useEffect, useContext, useState, useRef } from "react";

// Context
import { DeviceSessionContext } from "../../components/analytics/DeviceContext";
import {
  Context,
  GetOrCreateSessionAuthContext,
  GetSubsplash,
  navigateTo,
} from "../../config/UserAuthContext";
import {
  EnvironmentConfigContext,
  SubscriptionPeriod,
} from "../../config/Environment";
import ApiInstance from "../../lib/ApiFunction";
import { useRemoteConfig } from "../../config/FBRemoteConfigContext";

// Analytics
import Utm from "../../components/analytics/UTM";
import {
  GA4AnalyticsContext,
  MixPanelAnalyticsContext,
} from "../../components/analytics/Analytics";
import TagManager from "react-gtm-module";

// Packages
import { PulseLoader } from "react-spinners";
import { Helmet } from "react-helmet";
import { useIsVisible } from "../../hooks/useIsVisible";
import { handleAutoPlay } from "../../lib/HelperFunctions";
import { toast } from "react-toastify";
import Cookies from "js-cookie";
import { useLocation, useNavigate } from "react-router-dom";

// Components
import BenjaminCTA from "../../components/home-landing/BenjaminCTA/BenjaminCTA";
import MapleCTA from "../../components/home-landing/MapleCTA/MapleCTA";
import ValuesChecklist from "../../components/common/ValuesChecklist";
import AppReviewSlider from "../../components/common/AppReviewsSlider";
import EndorsementsLogos from "../../components/home-landing/Endorsements/EndorsementsLogos";
import VideoReviewSlider from "../../components/common/VideoReviewsSlider";
import Video from "../../components/common/Video";
import Button from "../../components/common/Button";

// Assets
import BGLandingImage from "../../global-assets/assets/BG_Image_Landing.webp";
import HuckabeeLogo from "../../global-assets/assets/logo-huckabee-alt.png";
import CandacePodcastLogo from "../../global-assets/assets/logo-candace-bure-podcast.webp";
import ChristianityTodayLogo from "../../global-assets/assets/logo-christianity-today.png";
import PromiseKeepersLogo from "../../global-assets/assets/logo-promise-keepers.png";
import BillyGrahamLogo from "../../global-assets/assets/logo-billy-graham.svg";
import PluggedInLogo from "../../global-assets/assets/logo-plugged-in.webp";
// import MediShareLogo from "../../global-assets/assets/logo-medi-share.png";
import HeroPromo from "../../global-assets/assets/Promo_New_2_040224-min.png";
import EasterHeroPromo from "../../global-assets/assets/hero-promo-easter.png";
import accentStars1 from "../../global-assets/assets/Accent_Stars_01.svg";
import accentStars2 from "../../global-assets/assets/Accent_Stars_02.svg";
import BibleGames from "../../global-assets/assets/platform-screenshot-bible-games.webp";
import MultipleProfiles from "../../global-assets/assets/platform-screenshot-multiple-profiles.webp";
import WorldOfRhym from "../../global-assets/assets/platform-screenshot-world-of-rhym-alt.webp";
import FunForAll from "../../global-assets/assets/platform-screenshot-fun-for-all.webp";
import BiblicalLessons from "../../global-assets/assets/platform-screenshot-biblical-lessons-alt.webp";
import ComicsVideos from "../../global-assets/assets/platform-screenshot-comics-videos.webp";
import MapleImg from "../../global-assets/assets/cta-maple.png";
import EasterBanner from "../../global-assets/assets/Easter-Banner-Corner.png";
import ThumbTwoBoys from "../HomeLanding/assets/thumbnail_2_boys_talking_about_ben.webp";
import ThumbExcitedMom1 from "../HomeLanding/assets/thumbnail_excited_mom_part_1.webp";
import ThumbExcitedMom2 from "../HomeLanding/assets/thumbnail_excited_mom_part_2.webp";
import ThumbFamilyInCar from "../HomeLanding/assets/thumbnail_family_in_car.webp";
import ThumbGirlPlayingMaple from "../HomeLanding/assets/thumbnail_girl_playing_maple.webp";
import ThumbLoveForGod from "../HomeLanding/assets/thumbnail_love_for_god.webp";
import ThumbMomsApp from "../HomeLanding/assets/thumbnail_moms_new_favorite_app.webp";
import ThumbMomTwoBoys from "../HomeLanding/assets/thumbnail_mom_with_two_boys.webp";
import ThumbMomTwoKids from "../HomeLanding/assets/thumbnail_mom_with_two_kids.webp";
import ThumbOliverFavorite from "../HomeLanding/assets/thumbnail_oliver_and_the_vindictive_vines_is_his_favorite_truplay_game.webp";
import ThumbStickerPack from "../HomeLanding/assets/thumbnail_sticker_pack.webp";
import ThumbTestimonial from "../HomeLanding/assets/thumbnail_truplay_testimonial.webp";
import ThumbDemo from "../HomeLanding/assets/thumbnail_homepage_demo.webp";
import ThumbTwoGirls from "../HomeLanding/assets/thumbnail_2_girls_playing_on_ipad.webp";
import ThumbKimWalker from "../HomeLanding/assets/thumbnail_Kim_Walker.webp";

const checkmarkPoints = [
  "One app, multiple high-quality Christian games.",
  "New content added all the time.",
  "Positive Biblical values in every piece of content.",
  "One subscription: no ads, chatrooms, or in-app purchases.",
];

const endorsedLogos = {
  christianityToday: { id: "christianityToday", image: ChristianityTodayLogo },
  candace: { id: "candace", image: CandacePodcastLogo },
  billy: { id: "billy", image: BillyGrahamLogo },
  pk: { id: "pk", image: PromiseKeepersLogo },
  pluggedIn: { id: "pluggedIn", image: PluggedInLogo },
  huckabee: { id: "huckabee", image: HuckabeeLogo },
  // mediShare: { id: "mediShare", image: MediShareLogo },
};

interface RequestPayload {
  priceId: string | null | undefined;
  successUrl: string;
  cancelUrl: string;
  promoCode?: string | null | undefined;
  metadata?: any;
}

interface VideoInfo {
  [key: string]: {
    id: number;
    url: string;
    title: string;
    autoPlay?: boolean;
    visible: boolean;
    loop?: boolean; // Default is false
    muted?: boolean; // Default is false
    playsinline?: boolean; // Default is true
    shareLink?: string;
    thumbnail?: string;
  };
}

const videoDefaults = {
  ipadVideo: {
    id: 946337337,
    url: "https://truplay-videos.sfo3.cdn.digitaloceanspaces.com/homepage_demo%20(720p).mp4",
    title: "Discover TruPlay Games",
    autoPlay: false,
    visible: false,
    loop: true,
    muted: true,
    shareLink: "https://vimeo.com/946337337",
    thumbnail: ThumbDemo,
  },
};

interface VideoReview {
  idx: number;
  url: string;
  width: string | number;
  height: string | number;
  component: JSX.Element | null;
  visible: boolean;
  focus: boolean;
  title: string;
  thumbnail: string;
  shareLink: string;
  videoId: string | number;
}

const videoReviews: VideoReview[] = [
  {
    idx: 1,
    url: "https://truplay-videos.sfo3.cdn.digitaloceanspaces.com/love_for_god_-_truplay_ugc%20(720p).mp4",
    width: "100%",
    height: "100%",
    component: null,
    visible: false,
    focus: false,
    title: "TruPlay Testimony 1",
    thumbnail: ThumbLoveForGod,
    shareLink: "https://vimeo.com/943820182",
    videoId: 943820182,
  },
  {
    idx: 2,
    url: "https://truplay-videos.sfo3.cdn.digitaloceanspaces.com/mom_with_two_kids_-_truplay_ugc%20(720p).mp4",
    width: "100%",
    height: "100%",
    component: null,
    visible: false,
    focus: false,
    title: "TruPlay Testimony 2",
    thumbnail: ThumbMomTwoKids,
    shareLink: "https://vimeo.com/935498545",
    videoId: 935498545,
  },
  {
    idx: 3,
    url: "https://truplay-videos.sfo3.cdn.digitaloceanspaces.com/excited_mom_part_1_-_truplay_ugc%20(720p).mp4",
    width: "100%",
    height: "100%",
    component: null,
    visible: false,
    focus: false,
    title: "TruPlay Testimony 3",
    thumbnail: ThumbExcitedMom2,
    shareLink: "https://vimeo.com/935518118",
    videoId: 935518118,
  },
  {
    idx: 4,
    url: "https://truplay-videos.sfo3.cdn.digitaloceanspaces.com/mom's_new_favorite_app_-_truplay_ugc%20(720p).mp4",
    width: "100%",
    height: "100%",
    component: null,
    visible: false,
    focus: false,
    title: "TruPlay Testimony 4",
    thumbnail: ThumbMomsApp,
    shareLink: "https://vimeo.com/943824997",
    videoId: 943824997,
  },
  {
    idx: 5,
    url: "https://truplay-videos.sfo3.cdn.digitaloceanspaces.com/truplay_testimonial_-_pov%20(720p).mp4",
    width: "100%",
    height: "100%",
    component: null,
    visible: false,
    focus: false,
    title: "TruPlay Testimony 5",
    thumbnail: ThumbTestimonial,
    shareLink: "https://vimeo.com/935509797",
    videoId: 935509797,
  },
  {
    idx: 6,
    url: "https://truplay-videos.sfo3.cdn.digitaloceanspaces.com/excited_mom_part_2_-_truplay_ugc%20(720p).mp4",
    width: "100%",
    height: "100%",
    component: null,
    visible: false,
    focus: false,
    title: "TruPlay Testimony 6",
    thumbnail: ThumbExcitedMom1,
    shareLink: "https://vimeo.com/935512425",
    videoId: 935512425,
  },
  {
    idx: 7,
    url: "https://truplay-videos.sfo3.cdn.digitaloceanspaces.com/the_best_app_for_kids_-_truplay_ugc%20(720p).mp4",
    width: "100%",
    height: "100%",
    component: null,
    visible: false,
    focus: false,
    title: "TruPlay Testimony 7",
    thumbnail: ThumbTwoGirls,
    shareLink: "https://vimeo.com/969178390",
    videoId: 969178390,
  },
  {
    idx: 8,
    url: "https://truplay-videos.sfo3.cdn.digitaloceanspaces.com/Kim%20Walker%20Smith%20talking%20about%20TruPlay_3.mp4",
    width: "100%",
    height: "100%",
    component: null,
    visible: false,
    focus: false,
    title: "TruPlay Testimony 8",
    thumbnail: ThumbKimWalker,
    shareLink: "https://vimeo.com/1002850401",
    videoId: 1002850401,
  },
  {
    idx: 9,
    url: "https://truplay-videos.sfo3.cdn.digitaloceanspaces.com/oliver_and_the_vindictive_vines_is_his_favorite_truplay_game_-_truplay_ugc%20(720p).mp4",
    width: "100%",
    height: "100%",
    component: null,
    visible: false,
    focus: false,
    title: "TruPlay Testimony 9",
    thumbnail: ThumbOliverFavorite,
    shareLink: "https://vimeo.com/945866477",
    videoId: 945866477,
  },
  {
    idx: 10,
    url: "https://truplay-videos.sfo3.cdn.digitaloceanspaces.com/2_boys_talking_about_ben_-_truplay_testimonial%20(720p).mp4",
    width: "100%",
    height: "100%",
    component: null,
    visible: false,
    focus: false,
    title: "TruPlay Testimony 10",
    thumbnail: ThumbTwoBoys,
    shareLink: "https://vimeo.com/935492076",
    videoId: 935492076,
  },
  {
    idx: 11,
    url: "https://truplay-videos.sfo3.cdn.digitaloceanspaces.com/family_in_car_-_truplay_ugc%20(720p).mp4",
    width: "100%",
    height: "100%",
    component: null,
    visible: false,
    focus: false,
    title: "TruPlay Testimony 11",
    thumbnail: ThumbFamilyInCar,
    shareLink: "https://vimeo.com/943822843",
    videoId: 943822843,
  },
  {
    idx: 12,
    url: "https://truplay-videos.sfo3.cdn.digitaloceanspaces.com/sticker_pack_-_truplay_testimonials%20(720p).mp4",
    width: "100%",
    height: "100%",
    component: null,
    visible: false,
    focus: false,
    title: "TruPlay Testimony 12",
    thumbnail: ThumbStickerPack,
    shareLink: "https://vimeo.com/935507967",
    videoId: 935507967,
  },
  {
    idx: 13,
    url: "https://truplay-videos.sfo3.cdn.digitaloceanspaces.com/girl_playing_maple_-_truplay_ugc%20(720p).mp4",
    width: "100%",
    height: "100%",
    component: null,
    visible: false,
    focus: false,
    title: "TruPlay Testimony 13",
    thumbnail: ThumbGirlPlayingMaple,
    shareLink: "https://vimeo.com/943826769",
    videoId: 943826769,
  },
  {
    idx: 14,
    url: "https://truplay-videos.sfo3.cdn.digitaloceanspaces.com/mom_with_two_boys_-_truplay_ugc%20(720p).mp4",
    width: "100%",
    height: "100%",
    component: null,
    visible: false,
    focus: false,
    title: "TruPlay Testimony 14",
    thumbnail: ThumbMomTwoBoys,
    shareLink: "https://vimeo.com/935503763",
    videoId: 935503763,
  },
];

export default function GiftASubscription() {
  const navigate = useNavigate();
  const firebaseFlags = useRemoteConfig();
  const mixPanelAnalytics = useContext(MixPanelAnalyticsContext);
  const ga4Context = useContext(GA4AnalyticsContext);
  const initialUserContext = GetOrCreateSessionAuthContext();
  const { GenerateStripeGiftLink } = ApiInstance();
  const [deviceCtx] = useState<DeviceSessionContext>(
    new DeviceSessionContext()
  );
  const osType = deviceCtx.os.name;
  const [userContext] = useState(initialUserContext);
  const config = useContext(EnvironmentConfigContext);
  const GlobalUserId = Cookies.get("GlobalUserId");
  const analyticsContext = useContext(MixPanelAnalyticsContext);
  const [showHolidayOffer, setShowHolidayOffer] = useState(false);
  const subsplash: any = GetSubsplash();
  const [featureFlagLoading, setFeatureFlagLoading] = useState<boolean>(true);
  const [easterPromo, setEasterPromo] = useState<boolean>(false);
  const [springSale, setSpringSale] = useState<boolean>(false);

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const videoToShow: string | null = params.get("video");

  const carouselRef = useRef(null);
  const ipadVideoRef = useRef<HTMLDivElement>(null);
  const isIpadVideoVisible = useIsVisible(ipadVideoRef, 1.0);
  const [currentAutoPlayVideos, setCurrentAutoPlayVideos] =
    useState<VideoInfo | null>(videoDefaults);
  const [shiftedVideos, setShiftedVideos] = useState<VideoReview[] | null>(
    null
  );
  const [mobileView, setMobileView] = useState<boolean>(false);

  Cookies.remove(Context.FreeTrial);
  Cookies.remove(Context.Pk);

  const giftAnnualPrice: number =
    config?.productConfig.giftingProductPrice.get(
      SubscriptionPeriod.GiftAnnual
    ) || 0;

  const giftAnnualMonthlyPrice = (giftAnnualPrice / 12).toFixed(2);

  const giftHolidayPrice: number =
    config?.productConfig.giftingProductPrice.get(
      SubscriptionPeriod.GiftHoliday
    ) || 0;

  /**
   * Initiate Stripe checkout process
   * @param stripePlanType
   */
  const handleGiftPurchaseLink = (stripePlanType: string) => {
    const addMetadata = (key: any, value: any) => {
      if (value) {
        requestPayload.metadata.push({ Key: key, Value: value });
      }
    };
    ga4Context.track("enter_stripe_flow", { stripePlanType: "GiftAnnual" });

    const priceId = config?.productConfig.giftingProductId.get(
      SubscriptionPeriod.GiftAnnual
    );

    let requestPayload: RequestPayload = {
      priceId: priceId,
      successUrl: `${window.location.origin}/gift-success`,
      cancelUrl: window.location.href,
      metadata: [],
    };

    if (stripePlanType === "Annual Holiday") {
      const holidayPromoCode = config.couponCodes.holidayPromoCode;
      requestPayload = { ...requestPayload, promoCode: holidayPromoCode };
    }

    addMetadata("google_client_id", Cookies.get("_ga"));
    addMetadata("affiliate", subsplash ? "subsplash" : null);

    GenerateStripeGiftLink.post("", requestPayload)
      .then((res) => {
        const { checkoutUrl } = res.data;
        analyticsContext.track("Web: Payment-In-Stripe-Initiated", {
          plan: "GiftAnnual",
          price_id: requestPayload.priceId || "",
        });
        window.open(`${checkoutUrl}`, "_self");
      })
      .catch((err) => {
        if (err.response.status === 401) {
          analyticsContext.track("Web: Payment-In-Stripe-Failed", {
            reason: "Session Timed Out",
          });
          Cookies.remove(Context.AuthContext);
          navigate("/login");
        }

        if (err.response) {
          toast.info(err.response.data.statusMessage);
        }
      });

    TagManager.dataLayer({
      dataLayer: {
        event: "plan_type_select",
        os_type: osType,
        plan_type: "GiftAnnual",
        custom_user_id: GlobalUserId || undefined,
      },
    });
  };

  const onCTAClick = (page: string, buttonName: string) => {
    TagManager.dataLayer({
      dataLayer: {
        event: "select_content",
        content_type: "button",
        link_name: buttonName,
        link_url: `/${page}`,
        os_type: deviceCtx.os.name,
        plan_type: userContext.subscription.sources[0]?.productId ?? "none",
        custom_user_id: GlobalUserId || undefined,
      },
    });

    navigateTo(`/${page}`);
  };

  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: "page_view",
        page_name: "marketing: landing: gift-a-subscription",
        page_type: "marketing",
        os_type: deviceCtx.os.name,
        plan_type: userContext.subscription.sources[0]?.productId ?? "none",
        custom_user_id: GlobalUserId || undefined,
      },
    });

    let utm = new Utm(mixPanelAnalytics);
    utm.track("Web: Gift-A-Sub-Landing-Page-Viewed", deviceCtx);
  }, []);

  // Add a video to the visibleVidoes object to enable autoplay once a ref
  // and isVisible check has been created for it
  useEffect(() => {
    const visibleVideos = {
      ipadVideo: isIpadVideoVisible,
    };
    handleAutoPlay(
      visibleVideos,
      currentAutoPlayVideos,
      setCurrentAutoPlayVideos
    );

    const videoToPlay: any = document.getElementById(
      videoDefaults.ipadVideo.id.toString() + "_mobile" || ""
    );
    if (videoToPlay) {
      if (currentAutoPlayVideos?.ipadVideo.autoPlay) {
        videoToPlay.play();
      } else {
        videoToPlay.pause();
      }
    }

    // Missing deps will causes infinite re-render, ignoring deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isIpadVideoVisible]);

  const renderHero = () => {
    if (firebaseFlags.easterDiscount === undefined) {
      return (
        <div className="flex min-h-[600px] items-center justify-center">
          <PulseLoader color="#ffffff" />
        </div>
      );
    }
    return (
      <div className="relative mx-auto flex w-full flex-col items-center gap-12 lg:flex-row">
        <div className="flex w-full flex-1 flex-col gap-10">
          <h1 className="text-transparent relative max-w-[340px] bg-gradient_text bg-clip-text text-[2.3rem] sm:mx-auto sm:max-w-[450px] sm:text-center sm:text-[3rem] homemd:max-w-[560px] md:max-w-none lg:hidden">
            Give the Gift of TruPlay
          </h1>
          <div className="flex items-center justify-center">
            <img
              src={firebaseFlags.easterDiscount ? EasterHeroPromo : HeroPromo}
              alt="TruPlay Games"
              loading="eager"
            />
          </div>
        </div>
        <div className="flex w-full flex-1 flex-col gap-10">
          <div className="flex flex-col gap-5">
            <h1 className="text-transparent relative hidden max-w-[340px] bg-gradient_text bg-clip-text text-[2.3rem] sm:mx-auto sm:max-w-[450px] sm:text-[3rem] homemd:max-w-none lg:block lg:text-left">
              Give the Gift of TruPlay
              <img
                src={accentStars1}
                loading="lazy"
                alt=""
                className="absolute -left-[70px] -top-[13px]"
              />
            </h1>
            <ValuesChecklist points={checkmarkPoints} />{" "}
          </div>
          <Button
            id="select_subscription_gift"
            onClick={() => handleGiftPurchaseLink("Annual")}
            btnType="green"
            className="w-full"
          >
            Purchase Gift
          </Button>
        </div>
      </div>
    );
  };

  const renderPrices = () => {
    if (
      firebaseFlags.easterDiscount === undefined &&
      firebaseFlags.springSale === undefined &&
      firebaseFlags.thanksgivingPriceDiscount2023 === undefined
    ) {
      return (
        <div className="flex min-h-[500px] items-center justify-center">
          <PulseLoader color="#ffffff" />
        </div>
      );
    }
    return (
      <div className="relateive flex w-full flex-col gap-4 homemd:max-w-[500px] md:max-w-[600px] lg:max-w-[800px]">
        <div className="relative flex flex-1 flex-col items-center justify-center gap-3 self-stretch rounded-[40px] border-4 border-solid border-turquoise-500 bg-white/5 p-6 shadow-[0px_4px_80px_0px_rgba(122,201,197,0.1)_inset] lg:max-w-[800px] lg:px-16 lg:py-10">
          {easterPromo ? (
            <img
              className="absolute right-0 top-0 max-w-[100px]"
              src={EasterBanner}
              alt="Easter Sale Banner"
            />
          ) : springSale ? (
            <>
              <div className="absolute -right-[14px] -top-[53px] h-[160px] w-[80px] -rotate-45 overflow-hidden">
                <div className="relative -left-[147px] -top-[15px] h-[113px] w-[220px] rotate-45 rounded-se-[40px] bg-turquoise-500" />
              </div>
              <div className="absolute -right-[15px] top-[20px] max-w-[100px] rotate-45 font-display text-base font-bold uppercase !leading-[1.2] text-black">
                Spring Sale!
              </div>
            </>
          ) : (
            <>
              {/* TODO: Replace with asset once available */}
              <div className="absolute -right-[14px] -top-[53px] h-[160px] w-[80px] -rotate-45 overflow-hidden">
                <div className="relative -left-[147px] -top-[15px] h-[113px] w-[220px] rotate-45 rounded-se-[40px] bg-turquoise-500" />
              </div>
              <div className="absolute -right-[15px] top-[15px] max-w-[100px] rotate-45 font-display text-base font-bold uppercase !leading-[1.2] text-black">
                Best Value
              </div>
            </>
          )}
          <h3 className="text-white">Annual Plan</h3>
          <h2 className="text-white drop-shadow-[0px_0px_16px_#38a6d5]">
            ${giftAnnualMonthlyPrice} / month
          </h2>
          <p className="text-indigo-200/80 lg:text-[2rem] lg:font-normal lg:leading-normal">
            {
              <>
                (<span className="line-through">$155.88</span>
                &nbsp;$
                {showHolidayOffer ? giftHolidayPrice : giftAnnualPrice} billed
                annually)
              </>
            }
          </p>
        </div>
      </div>
    );
  };

  const handleCenterOnVideo = (videoIndex: number) => {
    let currentList = [...videoReviews];
    while (currentList[Math.floor(currentList.length / 2)].idx !== videoIndex) {
      const popped = currentList[currentList.length - 1];
      currentList.pop();
      currentList.unshift(popped);
    }
    setShiftedVideos(currentList);
  };

  useEffect(() => {
    videoReviews.every((video) => {
      if (video.videoId.toString() === videoToShow) {
        handleCenterOnVideo(video.idx);
        return false;
      }
      return true;
    });
  }, [videoToShow]);

  const handleResize = () => {
    if (window.innerWidth <= 992) {
      setMobileView(true);
    } else {
      setMobileView(false);
    }
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div id="test_data_gift_a_sub_page" className="min-h-screen">
      <Helmet>
        <title>
          Gift Christian Games - TruPlay Gaming Subscriptions & More
        </title>
        <meta
          name="description"
          content="Gift a TruPlay subscription—perfect for guiding young minds through joyful, faith-based learning and entertainment in a secure environment."
        />
        <link rel="canonical" href="https://www.truplaygames.com/gift" />
      </Helmet>
      <div className="relative overflow-hidden px-5 pb-16 pt-24 sm:px-6 md:px-7">
        <div className="tru-home-blue-bg1 absolute bottom-0 left-0 right-0 top-0 -z-[1] opacity-15 lg:opacity-30" />
        <div className="tru-home-green-bg1 absolute bottom-0 left-0 right-0 top-0 -z-[2] opacity-25 lg:opacity-50" />
        <div className="tru-home-purple-bg1 absolute bottom-0 left-0 right-0 top-0 -z-[1] opacity-20 lg:opacity-40" />
        <div className="mx-auto max-w-[1440px]">{renderHero()}</div>
      </div>
      <div className="relative overflow-hidden bg-white/5 px-5 py-12 shadow-[0_0_80px_0_#001b36] sm:px-6 sm:py-16 md:px-7 md:py-24 lg:px-12 lg:py-32">
        <div className="tru-home-purple-bg2 absolute bottom-0 left-0 right-0 top-0 -z-[1] opacity-15 lg:opacity-30" />
        <div className="tru-home-green-bg2 absolute bottom-0 left-0 right-0 top-0 -z-[2] opacity-15 lg:opacity-30" />
        <div className="mx-auto max-w-[1440px]">
          <div className="relative mx-auto flex w-full flex-col items-center gap-6 text-center">
            <h2 className="text-transparent relative mb-8 max-w-[268px] bg-gradient_text bg-clip-text sm:max-w-[300px] md:max-w-none">
              Gift a TruPlay Subscription
              <img
                className="absolute -right-[90px] -top-[40px] hidden lg:block"
                src={accentStars2}
                loading="lazy"
                alt=""
              />
            </h2>
            {renderPrices()}
          </div>
        </div>
      </div>
      <div className="relative mx-auto flex w-full flex-col gap-8 px-5 py-12 pt-6 text-center sm:px-6 sm:py-16 md:px-7 md:py-24 lg:gap-20 lg:px-12 lg:py-32">
        <div className="tru-home-purple-bg3 absolute bottom-0 left-0 right-0 top-0 -z-[1] opacity-20 lg:opacity-40" />
        <div className="tru-home-green-bg3 absolute bottom-0 left-0 right-0 top-0 -z-[2] opacity-15 lg:opacity-30" />
        <div className="tru-home-blue-bg-video absolute bottom-0 left-0 right-0 top-0 -z-[2] opacity-20 lg:opacity-40" />
        <div className="tru-home-blue-bg2 absolute bottom-0 left-0 right-0 top-0 -z-[1] opacity-15 lg:opacity-30" />
        <div className="tru-home-purple-bg4 absolute bottom-0 left-0 right-0 top-0 -z-[1] opacity-15 lg:opacity-30" />
        <div className="tru-home-blue-bg-iphones absolute bottom-0 left-0 right-0 top-0 -z-[2] opacity-20 lg:opacity-40" />
        <div className="tru-home-green-bg4 absolute bottom-0 left-0 right-0 top-0 -z-[1] opacity-10 lg:opacity-20" />
        <div className="tru-home-blue-bg3 absolute bottom-0 left-0 right-0 top-0 -z-[1] opacity-15 lg:opacity-30" />
        <div className="tru-home-purple-bg5 absolute bottom-0 left-0 right-0 top-0 -z-[1] opacity-15 lg:opacity-30" />
        <div className="mx-auto flex max-w-[1440px] flex-col items-center gap-y-16 md:gap-y-20 lg:gap-24">
          <div className="flex flex-col items-center gap-4 text-center">
            <h2 className="text-transparent relative bg-gradient_text bg-clip-text lg:max-w-[790px] xl:max-w-none">
              Games Kids Love <br className="lg:hidden" />& Parents Can Trust
              <img
                className="absolute -left-[20px] -top-[20px] hidden lg:block xl:-left-[70px]"
                src={accentStars1}
                loading="lazy"
                alt=""
              />
            </h2>
            <h1 className="mx-auto font-body text-base font-normal leading-normal text-white lg:text-[2rem]">
              Explore our collection of Christian Games and Bible-Based Content.
            </h1>
          </div>
          <div
            ref={ipadVideoRef}
            className="mx-auto w-full max-w-[1000px] flex-1"
          >
            <Video
              id={
                videoDefaults.ipadVideo.id.toString() +
                (mobileView ? "_mobile" : "")
              }
              videoId={videoDefaults.ipadVideo.id.toString()}
              url={videoDefaults.ipadVideo.url}
              shareUrl={videoDefaults.ipadVideo.shareLink}
              playsInline
              thumbnail={ThumbDemo}
              iPadView
              borderColor="bg-black"
              controls={mobileView ? false : true}
              loop={mobileView ? true : false}
              muted={mobileView ? true : false}
            />
          </div>
          <div className="flex w-full flex-col gap-4 lg:flex-row lg:gap-12">
            <div className="flex flex-1 flex-col items-center gap-4 rounded-[40px] bg-[rgba(255,255,255,0.06)] p-8 shadow-[0px_0px_80px_0px_#001b36]">
              <h2 className="text-[2.75rem] leading-normal text-white">100+</h2>
              <p className="text-xl font-normal !leading-normal text-indigo-200 md:text-3xl lg:text-[2rem]">
                Hours of Content and growing
              </p>
            </div>
            <div className="flex flex-1 flex-col items-center gap-4 rounded-[40px] bg-[rgba(255,255,255,0.06)] p-8 shadow-[0px_0px_80px_0px_#001b36]">
              <h2 className="text-[2.75rem] leading-normal text-white">35+</h2>
              <p className="text-xl font-normal !leading-normal text-indigo-200 md:text-3xl lg:text-[2rem]">
                Games, Comics and Videos all in one app
              </p>
            </div>
            <div className="flex flex-1 flex-col items-center gap-4 rounded-[40px] bg-[rgba(255,255,255,0.06)] p-8 shadow-[0px_0px_80px_0px_#001b36]">
              <h2 className="text-[2.75rem] leading-normal text-white">
                Ages 4-12
              </h2>
              <p className="text-xl font-normal !leading-normal text-indigo-200 md:text-3xl lg:text-[2rem]">
                Biblical lessons tailored for your children
              </p>
            </div>
          </div>
        </div>
        <div className="relative mx-auto flex w-full flex-col items-center gap-8 text-center md:gap-12 lg:gap-16">
          <div className="flex flex-col gap-4">
            <h2 className="text-transparent relative mx-auto max-w-[700px] bg-gradient_text bg-clip-text lg:max-w-none">
              Your Subscription Includes
              <img
                className="absolute -right-[95px] -top-[40px] left-auto hidden lg:block"
                src={accentStars2}
                loading="lazy"
                alt=""
              />
            </h2>
            <p className="max-w-[1000px] text-white lg:text-[2rem] lg:font-normal lg:leading-normal">
              A RhymVerse of character and faith-building adventures featuring
              dozens of games, animated shorts, and comics!
            </p>
          </div>
          <div className="grid grid-cols-1 gap-y-10 homemd:grid-cols-2 homemd:gap-x-10 lg:grid-cols-3 lg:gap-y-20">
            <div className="flex max-w-[285px] flex-col gap-4 sm:max-w-[315px]">
              <img src={BibleGames} alt="" loading="lazy" />
              <div className="flex flex-col gap-4">
                <h4 className="text-white">Bible Games</h4>
                <p className="text-indigo-200">
                  Biblical scripture and parable lessons are organically
                  integrated into game play.
                </p>
              </div>
            </div>
            <div className="flex max-w-[285px] flex-col gap-4 sm:max-w-[315px]">
              <img src={WorldOfRhym} alt="" loading="lazy" />
              <div className="flex flex-col gap-4">
                <h4 className="text-white">The World of Rhym</h4>
                <p className="text-indigo-200 ">
                  Games take place in an original universe built to help little
                  ones grow in character and faith.
                </p>
              </div>
            </div>
            <div className="flex max-w-[285px] flex-col gap-4 sm:max-w-[315px]">
              <img src={ComicsVideos} alt="" loading="lazy" />
              <div className="flex flex-col gap-4">
                <h4 className="text-white">Comics & Videos</h4>
                <p className="text-indigo-200">
                  Featuring stories from Book of God, The Action Bible, and high
                  quality originals.
                </p>
              </div>
            </div>
            <div className="flex max-w-[285px] flex-col gap-4 sm:max-w-[315px]">
              <img src={BiblicalLessons} alt="" loading="lazy" />
              <div className="flex flex-col gap-4">
                <h4 className="text-white">Biblical Lessons</h4>
                <p className="text-indigo-200">
                  Every game and video on TruPlay is inspired by the lessons and
                  stories in the Bible.
                </p>
              </div>
            </div>
            <div className="flex max-w-[285px] flex-col gap-4 sm:max-w-[315px]">
              <img src={MultipleProfiles} alt="" loading="lazy" />
              <div className="flex flex-col gap-4">
                <h4 className="text-white">Multiple Profiles</h4>
                <p className="text-indigo-200">
                  Download the TruPlay app on up to 10 users at no additional
                  charge.
                </p>
              </div>
            </div>
            <div className="flex max-w-[285px] flex-col gap-4 sm:max-w-[315px]">
              <img src={FunForAll} alt="" loading="lazy" />
              <div className="flex flex-col gap-4">
                <h4 className="text-white">Fun for All</h4>
                <p className="text-indigo-200">
                  A wide range of games and activities for kids (and parents) to
                  enjoy.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="relative overflow-hidden px-5 py-12 sm:px-6 sm:py-16 md:px-7 md:py-24 lg:px-12 lg:py-32">
        <div className="mx-auto flex max-w-[1440px] flex-col items-center justify-center">
          <BenjaminCTA
            title="Ready to play?"
            mobileTitle="Ready to play?"
            buttonText="Purchase Gift"
            mobileButtonText="Purchase Gift"
            ctaAction={() => handleGiftPurchaseLink("Annual")}
          />
        </div>
      </div>
      <div className="relative overflow-hidden bg-white/5 py-12 shadow-[0_0_80px_0_#001b36] sm:py-16 md:py-24 lg:py-32">
        <div className="tru-home-purple-bg6 absolute bottom-0 left-0 right-0 top-0 -z-[1] opacity-15 lg:opacity-30" />
        <div className="tru-home-green-bg5 absolute bottom-0 left-0 right-0 top-0 -z-[1] opacity-10 lg:opacity-20" />
        <div className="tru-home-blue-bg4 absolute bottom-0 left-0 right-0 top-0 -z-[1] opacity-20 lg:opacity-40" />
        <div className="absolute bottom-0 left-0 right-auto top-0 z-[1] hidden w-[100px] bg-[linear-gradient(to_right,#001b36be,transparent_70%)] lg:inline" />
        <div className="absolute bottom-0 left-auto right-0 top-0 z-[1] hidden w-[100px] bg-[linear-gradient(to_left,#001b36be,transparent_70%)] lg:inline" />
        <div className="mx-auto max-w-[1440px]">
          <div className="relative mx-auto my-0 mb-8 flex w-full flex-col gap-6">
            <h2 className="text-transparent relative mx-auto bg-gradient_text bg-clip-text text-center">
              What Our Customers Say
              <img
                src={accentStars1}
                loading="lazy"
                alt=""
                className="absolute -left-[70px] -top-[20px] hidden lg:block"
              />
            </h2>
            <p className="mx-auto my-0 max-w-[900px] text-center text-white lg:text-[2rem] lg:font-normal lg:leading-normal">
              See what parents are saying about the all new Christian
              entertainment platform.
            </p>
          </div>
        </div>
        <div ref={carouselRef} className="flex flex-col gap-16">
          <VideoReviewSlider
            videos={shiftedVideos ? shiftedVideos : videoReviews}
            noTitle
          />
          <AppReviewSlider lightBackground noTitle />
        </div>
      </div>
      <div className="relative overflow-hidden px-5 py-12 sm:px-6 sm:py-16 md:px-7 md:py-24 lg:px-12 lg:py-32">
        <div className="tru-home-blue-bg5 absolute bottom-0 left-0 right-0 top-0 -z-[1] opacity-25 lg:opacity-50" />
        <div className="tru-home-green-bg6 absolute bottom-0 left-0 right-0 top-0 -z-[2] opacity-20 lg:opacity-40" />
        <div className="tru-home-purple-bg7 absolute bottom-0 left-0 right-0 top-0 -z-[1] opacity-25 lg:opacity-50" />
        <div className="tru-home-blue-bg6 absolute bottom-0 left-0 right-0 top-0 -z-[1] opacity-30 lg:opacity-30" />
        <div className="max-w-[2000px mx-auto lg:pb-[125px]">
          <EndorsementsLogos
            title="TruPlay is Endorsed by:"
            mobileTitle="Endorsed by:"
            noBackground
            logos={endorsedLogos}
          />
        </div>
        <div className="mx-auto flex max-w-[1440px] flex-col items-center justify-center gap-x-4 gap-y-[60px] md:gap-y-[75px] lg:gap-y-[150px]">
          <div className="relative mx-auto my-0 hidden w-full flex-col gap-6 lg:flex">
            <MapleCTA
              image={MapleImg}
              title="Start Playing in the Light"
              mobileTitle=""
              buttonText="Purchase Gift"
              mobileButtonText=""
              ctaAction={() => handleGiftPurchaseLink("Annual")}
            />
          </div>
          <div className="relative mx-auto flex w-full flex-col gap-6 text-center">
            <h2 className="text-transparent relative mx-auto bg-gradient_text bg-clip-text">
              Have Questions?
              <img
                src={accentStars1}
                loading="lazy"
                alt=""
                className="absolute -left-[70px] -top-[20px] hidden lg:block"
              />
            </h2>
            <div className="flex w-full flex-col gap-4 md:flex-row md:gap-8">
              <div
                className="flex flex-1 cursor-pointer flex-col items-center gap-4 rounded-3xl bg-[rgba(255,255,255,0.06)] p-8 shadow-[0_25px_20px_-20px_#001b36] duration-100 hover:-translate-y-2 hover:shadow-[0px_4px_30px_5px_rgba(56,166,213,0.6)]"
                onClick={() => onCTAClick("faq", "faq_button")}
              >
                <h4 className="text-white">
                  Visit our{" "}
                  <span className="text-cerulean-500 hover:underline">FAQ</span>{" "}
                  page
                </h4>
              </div>
              <div
                className="flex flex-1 cursor-pointer flex-col items-center gap-4 rounded-3xl bg-[rgba(255,255,255,0.06)] p-8 shadow-[0_25px_20px_-20px_#001b36] duration-100 hover:-translate-y-2 hover:shadow-[0px_4px_30px_5px_rgba(56,166,213,0.6)]"
                onClick={() => onCTAClick("contact", "contact_button")}
              >
                <h4 className="text-white">
                  Need help?{" "}
                  <span className="text-cerulean-500 hover:underline">
                    Contact Us
                  </span>
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="absolute bottom-auto left-0 right-0 top-0 -z-[3] mx-auto flex max-w-none overflow-hidden">
        <img
          src={BGLandingImage}
          loading="eager"
          sizes="100vw"
          alt=""
          className="relative -z-[1] w-full min-w-[1920px]"
        />
      </div>
    </div>
  );
}
