// Components //
import Video from "../../common/Video";
import TextWithContentSplit from "../../common/TextWithContentSplit";

// Assets //
import LargePlayBtn from "../../crowdfunding/assets/branded_play_btn.svg";
import ThumbOurMission from "../../crowdfunding/assets/thumbnail_our_mission.webp";

export default function AboutHero() {
  return (
    <TextWithContentSplit
      headerType="h1"
      headerText="TruPlay's Mission"
      subtext={`Our mission is to transform generations of children in such a
            profound way that it will shape culture. At TruPlay, we're
            building a beautiful, fun, world-class entertainment platform
            which invites children into a world of hope and God's truth.`}
      content={
        <Video
          id="truplays_mission"
          videoId={948925112}
          url="https://truplay-videos.sfo3.cdn.digitaloceanspaces.com/crowdfunding_join_the_movement%20(720p).mp4"
          shareUrl="https://vimeo.com/948925112"
          shareSmall
          controls
          playsInline
          thumbnail={ThumbOurMission}
          customPlayBtn={LargePlayBtn}
        />
      }
      subtextColor="text-white"
      contentLocation="right"
      textPositionOnMobile="above"
    />
  );
}
