// Components //
import Button from "../common/Button";
import BlurredSection from "../common/BlurredSection";

export default function HowItWorksCTA() {
  const openKickstarterPage = () => {
    window.open(
      "https://www.kickstarter.com/projects/truplaygames/truplay-bible-gaming-app-for-children?ref=w6gohd",
      "_blank"
    );
  };

  return (
    <BlurredSection fullWidth>
      <div className="flex flex-col items-center justify-center">
        <div className="flex flex-col gap-6 lg:gap-12">
          <div className="flex flex-col gap-4">
            <h3 className="text-center text-2xl text-white sm:text-3xl lg:text-4xl">
              Please Support us on Kickstarter Today!
            </h3>
            <p className="m-0 text-center text-xl font-normal text-indigo-200">
              Click below to Visit our Kickstarter Page. Thank You.
            </p>
          </div>
          <Button
            id="how_it_works_cta"
            className="w-full"
            btnType="green"
            onClick={openKickstarterPage}
          >
            Visit Kickstarter Page
          </Button>
        </div>
      </div>
    </BlurredSection>
  );
}
