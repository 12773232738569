interface INotificationBannerProps {
  backgroundColor?: string; // TailwindCSS classname
  leftBGImageMoblie?: string;
  rightBGImageMoblie?: string;
  leftBGImageDesktop?: string;
  rightBGImageDesktop?: string;
  textComponent: JSX.Element;
}

export default function NotificationBanner({
  backgroundColor = "bg-cerulean-500",
  leftBGImageMoblie,
  rightBGImageMoblie,
  leftBGImageDesktop,
  rightBGImageDesktop,
  textComponent,
}: INotificationBannerProps) {
  return (
    <div
      className={
        "relative flex h-[86px] w-full items-center justify-center pb-3 pt-2 md:h-auto md:pb-6 md:pt-4 " +
        backgroundColor
      }
    >
      {leftBGImageDesktop && (
        <div
          className="absolute left-0 top-0 z-[0] hidden 2xl:inline"
          style={{
            maskImage: `linear-gradient(
            -270deg,
            #000 0%,
            rgba(0, 0, 0, 0) 100%
          )`,
          }}
        >
          <img
            src={leftBGImageDesktop}
            alt=""
            loading="eager"
            className="h-[72px]"
          />
        </div>
      )}
      {leftBGImageMoblie && (
        <div
          className="absolute left-0 top-0 z-[0] 2xl:hidden"
          style={{
            maskImage: `linear-gradient(
            -270deg,
            #000 0%,
            rgba(0, 0, 0, 0) 100%
          )`,
          }}
        >
          <img
            src={leftBGImageMoblie}
            alt=""
            loading="eager"
            className="h-[86px] md:h-[72px]"
          />
        </div>
      )}
      <div className="z-[1] flex flex-col items-center px-4 md:flex-row md:gap-2">
        {textComponent}
      </div>
      {rightBGImageDesktop && (
        <div
          className="absolute right-0 top-0 z-[0] hidden 2xl:inline"
          style={{
            maskImage: `linear-gradient(
              270deg,
              #000 0%,
              rgba(0, 0, 0, 0) 100%
            )`,
          }}
        >
          <img
            src={rightBGImageDesktop}
            alt=""
            loading="eager"
            className="h-[72px]"
          />
        </div>
      )}
      {rightBGImageMoblie && (
        <div
          className="absolute right-0 top-0 z-[0] 2xl:hidden"
          style={{
            maskImage: `linear-gradient(
            270deg,
            #000 0%,
            rgba(0, 0, 0, 0) 100%
          )`,
          }}
        >
          <img
            src={rightBGImageMoblie}
            alt=""
            loading="eager"
            className="h-[86px] md:h-[72px]"
          />
        </div>
      )}
    </div>
  );
}
