import Button from "./Button";

interface IGameTileCTAProps {
  backgroundImage: string;
  backgroundGradient: string;
  gameTileImage: string;
  handleCTA?: (section: string) => void;
}

export default function GameTileCTA({
  backgroundImage,
  backgroundGradient,
  gameTileImage,
  handleCTA,
}: IGameTileCTAProps) {
  return (
    <div className="mx-auto flex max-w-[1440px] flex-col gap-12 lg:gap-32 lg:px-12 lg:pb-32 lg:pt-20 xl:pt-32 2xl:px-6 3xl:px-0">
      <div
        className="relative mx-auto hidden h-80 w-full max-w-[1440px] items-center justify-center rounded-[40px] bg-cover bg-center bg-no-repeat lg:flex xl:h-[22rem]"
        style={{ backgroundImage: `url('${backgroundImage}')` }}
      >
        <div
          className={
            "absolute bottom-0 left-0 right-0 top-0 -z-[1] rounded-[40px]" +
            (backgroundGradient ? " " + backgroundGradient : "")
          }
        />
        <div className="flex flex-1 items-center justify-center p-8 !pr-0 xl:p-16">
          <img
            src={gameTileImage}
            loading="lazy"
            alt="Lucas"
            className="mb-24 h-[400px] w-[400px] xl:mb-32 xl:h-[450px] xl:w-[450px] 2xl:mb-40 2xl:h-[500px] 2xl:w-[500px]"
          />
        </div>
        <div className="flex flex-1 flex-col items-center justify-center gap-6 p-8 xl:p-16">
          <div className="flex flex-col gap-3">
            <h2 className="text-center text-white">Ready to Play?</h2>
            <p className="m-0 text-center font-body text-lg font-normal text-indigo-200 xl:text-xl 2xl:text-2xl">
              Lucas Mission Starhope is one of many games{" "}
              <br className="hidden 2xl:inline" />
              available on TruPlay. Click below to get a Free Trial.
            </p>
          </div>
          {typeof handleCTA === "function" && (
            <Button
              id="lucas_footer_cta"
              onClick={() => handleCTA("footer")}
              btnType="green"
              className="z-10 w-full"
            >
              Redeem Your FREE Week
            </Button>
          )}
        </div>
      </div>
      <div
        className="relative mx-auto mt-24 flex w-full items-center justify-center bg-cover bg-center bg-no-repeat sm:mt-28 md:mt-32 lg:hidden"
        style={{ backgroundImage: `url('${backgroundImage}')` }}
      >
        <div
          className={
            "absolute bottom-0 left-0 right-0 top-0 -z-[1]" +
            (backgroundGradient ? " " + backgroundGradient : "")
          }
        />
        <img
          src={gameTileImage}
          alt="LMD Game Tile"
          loading="lazy"
          className="drop absolute -top-[120px] h-[280px] w-[280px] flex-shrink-0 rounded-[30px]"
        />
        <div className="flex flex-col items-center justify-center gap-6 px-6 pb-8 pt-36">
          <div className="flex flex-col gap-3">
            <h2 className="text-center text-white">Ready to Play?</h2>
            <p className="m-0 text-center font-body text-base font-normal text-indigo-200">
              This is one of many games available on TruPlay. Click below to get
              a Free Trial.
            </p>
          </div>
          {typeof handleCTA === "function" && (
            <Button
              id="lucas_footer_cta"
              onClick={() => handleCTA("footer")}
              btnType="green"
              className="z-10 w-full"
            >
              Try for FREE
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}
