import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import "react-tooltip/dist/react-tooltip.css";
import Cookies from "js-cookie";
import {
  Context,
  GetOrCreateSessionAuthContext,
  Logout,
  navigateTo,
} from "../../../../config/UserAuthContext";
import { PasswordValidator } from "../../../../config/UserInputValidator";
import { InlineErrorMessage } from "../../../notification/InlineErrorMessage/InlineErrorMessage";
import AccountServiceClient from "../../../../lib/ApiFunction";
import { ErrorCodeMap } from "../../../../lib/Constants";
import ModalContainer from "../../../common/ModalContainer";
import closeIcon from "../../../../global-assets/assets/icon-close-gray.svg";
import eyeShowIcon from "../../../../global-assets/assets/icon-eye-show.svg";
import eyeHideIcon from "../../../../global-assets/assets/icon-eye-hide.svg";

// Firebase
import { updatePassword } from "firebase/auth";
import { auth } from "../../../../firebase";
import { useRemoteConfig } from "../../../../config/FBRemoteConfigContext";
import Button from "../../../common/Button";

interface ChangePasswordModalProps {
  handleClose: () => void;
  open: boolean;
  setAuth: any;
}

const ChangePasswordModal: React.FC<ChangePasswordModalProps> = ({
  handleClose,
  open,
  setAuth,
}) => {
  const { ChangePassword } = AccountServiceClient();

  const [currentPass, setCurrentPass] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [confirmPass, setConfirmPass] = useState<string>("");
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showCurrentPwd, setCurrentPwd] = useState<boolean>(false);
  const [showConfirmPwd, setConfirmPwd] = useState<boolean>(false);
  const [isCallingAPI, setIsCallingAPI] = useState<boolean>(false);

  const [hasPasswordValidationError, setHasPasswordValidationError] =
    useState<boolean>(false);
  const [currentPasswordError, setCurrentPasswordError] = useState<
    string | null
  >(null);
  const [newPasswordError, setNewPasswordError] = useState<string | null>(null);
  const [passwordValidError, setPasswordValidError] = useState<string | null>(
    null
  );
  const [loginError, setLoginError] = useState<string | null>(null);

  const currentPassRef = useRef<any>(null);
  const newPasswordRef = useRef<any>(null);
  const confirmNewPasswordRef = useRef<any>(null);

  const firebaseFlags = useRemoteConfig();

  useEffect(() => {
    if (newPasswordRef.current?.value)
      setPassword(newPasswordRef.current?.value);
    if (confirmNewPasswordRef.current?.value)
      setConfirmPass(confirmNewPasswordRef.current?.value);
  }, []);

  // Firebase change password
  const handleFirebaseChangePassword = async () => {
    setHasPasswordValidationError(false);
    setCurrentPasswordError(null);
    setNewPasswordError(null);
    setPasswordValidError(null);
    setLoginError(null);
    const hasError = await ValidateRegistrationDetails();
    if (hasError) {
      return;
    }
    const authContext = GetOrCreateSessionAuthContext();
    const user = auth.currentUser;

    if (authContext.token && user) {
      setIsCallingAPI(true);
      if (user) {
        updatePassword(user, password)
          .then(() => {
            setConfirmPass("");
            setPassword("");
            toast.success(
              "Password has been changed successfully. Navigating back to Log In to sign in with your new password."
            );

            setTimeout(() => {
              setAuth(false);
              Cookies.remove(Context.AuthContext);
              Cookies.remove(Context.UserContext);
              Cookies.remove(Context.Token);
              Cookies.remove(Context.RememberMe);
              Cookies.remove(Context.EmailAddress);

              navigateTo("/login");
              setIsCallingAPI(false);
            }, 3000);
          })
          .catch((error) => {
            setIsCallingAPI(false);
            // TODO: Update error handling once Firebase and Account Services are fully integrated
            const fbErrorCode = error.code;
            const fbErrorMessage = error.message;
            const errorMessage = ErrorCodeMap[fbErrorCode]
              ? ErrorCodeMap[fbErrorCode]
              : fbErrorMessage;

            if (fbErrorCode === "auth/requires-recent-login") {
              toast.warn(
                "Session timeout. Navigating back to the Log In page for re-authentication." +
                  " Please sign in again to change your password",
                {}
              );
              setTimeout(() => {
                Logout();
                navigateTo("/login");
              }, 4000);
            }

            setLoginError(errorMessage);
          });
      }
    } else {
      toast.warn("Session timeout. Navigating back to the Log In page.", {
        position: "top-center",
      });
      setTimeout(() => {
        setIsCallingAPI(false);
        navigateTo("/login");
      }, 4000);
    }
  };

  const handleChangePassword = async () => {
    setHasPasswordValidationError(false);
    setCurrentPasswordError(null);
    setNewPasswordError(null);
    setPasswordValidError(null);
    setLoginError(null);
    const hasError = await ValidateRegistrationDetails();
    if (hasError) {
      return;
    }
    const authContext = GetOrCreateSessionAuthContext();

    if (authContext.token) {
      setIsCallingAPI(true);
      ChangePassword.post("", {
        currentPassword: currentPass,
        newPassword: password,
      })
        .then(() => {
          setConfirmPass("");
          setPassword("");
          setCurrentPass("");
          toast.success("Password has been changed successfully");

          setAuth(false);
          Cookies.remove(Context.AuthContext);
          Cookies.remove(Context.UserContext);
          Cookies.remove(Context.Token);
          Cookies.remove(Context.RememberMe);
          Cookies.remove(Context.EmailAddress);

          navigateTo("/login");
        })
        .catch((error) => {
          setIsCallingAPI(false);
          if (error.response.status === 401) {
            toast.warn(
              "Session timeout. Navigating back to the Log In page.",
              {}
            );
            setTimeout(() => {
              navigateTo("/login");
            }, 4000);
          }

          let errorMessage = ErrorCodeMap[error.response?.data.statusCode]
            ? ErrorCodeMap[error.response?.data.statusCode]
            : error.response?.data.statusMessage;
          if (error?.response?.data?.statusCode === "AS004") {
            setNewPasswordError(errorMessage);
            setHasPasswordValidationError(true);
          } else if (
            error?.response?.data?.statusCode in ["AS005", "AS300", "AS3001"]
          ) {
            setCurrentPasswordError(errorMessage);
          } else {
            setLoginError(errorMessage);
          }
        });
    } else {
      toast.warn("Session timeout. Navigating back to the Log In page.", {
        position: "top-center",
      });
      setTimeout(() => {
        setIsCallingAPI(false);
        navigateTo("/login");
      }, 4000);
    }
  };

  const ValidateRegistrationDetails = async () => {
    let hasError: boolean = false;
    if (!(password === confirmPass)) {
      setNewPasswordError("Passwords do not match.");
      hasError = true;
      setHasPasswordValidationError(true);
    }

    let passwordValidator = new PasswordValidator(password);
    if (!hasError && passwordValidator.ErrorMessage) {
      setPasswordValidError(passwordValidator.ErrorMessage);
      hasError = true;
      setHasPasswordValidationError(true);
    }

    return hasError;
  };

  const handlePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleCurrentPwdVisibility = () => {
    setCurrentPwd(!showCurrentPwd);
  };

  const handleConfirmPwdVisibility = () => {
    setConfirmPwd(!showConfirmPwd);
  };

  const clearText = () => {
    setCurrentPass("");
    setPassword("");
    setConfirmPass("");
  };

  return (
    <ModalContainer isOpen={open}>
      <div className="flex flex-col justify-center gap-4 bg-white">
        <div
          className="absolute right-[14px] top-[14px] cursor-pointer pr-0 pt-0 opacity-60 hover:opacity-100 md:right-5 md:top-5 lg:right-6 lg:top-6"
          onClick={() => {
            handleClose();
            clearText();
          }}
        >
          <img src={closeIcon} loading="lazy" alt="" />
        </div>
        <h5 className="text-center font-display text-lg font-semibold capitalize text-indigo-500 sm:text-2xl md:text-3xl">
          Change Password
        </h5>
        <p className="m-0 text-lg text-cool-gray-900">
          Create a new password to login to the TruPlay platform across all
          devices. Changing this password may log you out of your devices.
        </p>
        <div className="flex flex-col gap-4">
          <div>
            {!firebaseFlags.firebaseAuth && (
              <>
                <div className="relative flex w-full">
                  <input
                    className={
                      "relative flex w-full rounded-lg border-0 bg-cool-gray-200" +
                      " px-2.5 py-4 text-left text-base text-indigo-500 outline-none" +
                      " placeholder:text-cool-gray-900/60 focus:bg-cool-gray-300" +
                      " focus:ring-2 focus:ring-cerulean-500 focus:placeholder:text-cool-gray-900/80" +
                      (currentPasswordError ? " ring-2 ring-raspberry-500" : "")
                    }
                    ref={currentPassRef}
                    type={showCurrentPwd ? "text" : "password"}
                    placeholder="Current Password"
                    value={currentPass}
                    onChange={(e) => {
                      setCurrentPass(e.target.value);
                    }}
                  />
                  <div className="absolute right-1.5 top-4">
                    <div
                      className="flex items-center justify-center"
                      role="button"
                      onClick={handleCurrentPwdVisibility}
                    >
                      {currentPass ? (
                        showCurrentPwd ? (
                          <img
                            src={eyeShowIcon}
                            alt="Show Password"
                            loading="lazy"
                            className="w-[22px] cursor-pointer text-cerulean-500"
                          />
                        ) : (
                          <img
                            src={eyeHideIcon}
                            alt="Hide Password"
                            loading="lazy"
                            className="w-[22px] cursor-pointer text-cerulean-500"
                          />
                        )
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
                <div className="ml-1 flex justify-end pt-1 text-right text-base font-semibold capitalize no-underline">
                  <Link
                    to="/forgot-password"
                    state={{ prevUrl: "/account-settings" }}
                    className="mb-2 text-cerulean-500 hover:underline"
                  >
                    Forgot?
                  </Link>
                </div>
                {currentPasswordError && (
                  <InlineErrorMessage errorMessage={currentPasswordError} />
                )}
              </>
            )}
          </div>
          <div className="relative mb-2 flex w-full flex-col gap-1">
            <div className="relative flex w-full">
              <input
                className={
                  "relative flex w-full rounded-t-lg border-0 bg-cool-gray-200" +
                  " px-2.5 py-4 text-left text-base text-indigo-500 outline-none" +
                  " placeholder:text-cool-gray-900/60 focus:bg-cool-gray-300" +
                  " rounded-b-0 focus:ring-2 focus:ring-cerulean-500 focus:placeholder:text-cool-gray-900/80" +
                  (hasPasswordValidationError
                    ? " ring-2 ring-raspberry-500"
                    : "")
                }
                value={password}
                placeholder="New Password"
                ref={newPasswordRef}
                type={showPassword ? "text" : "password"}
                onChange={(e) => {
                  setPassword(e.target.value);
                  setHasPasswordValidationError(false);
                }}
              />
              <div className="absolute right-1.5 top-4">
                <div
                  className="flex items-center justify-center"
                  role="button"
                  onClick={handlePasswordVisibility}
                >
                  {password ? (
                    showPassword ? (
                      <img
                        src={eyeShowIcon}
                        alt="Show Password"
                        loading="lazy"
                        className="w-[22px] cursor-pointer text-cerulean-500"
                      />
                    ) : (
                      <img
                        src={eyeHideIcon}
                        alt="Hide Password"
                        loading="lazy"
                        className="w-[22px] cursor-pointer text-cerulean-500"
                      />
                    )
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
            <div className="relative flex w-full">
              <input
                className={
                  "relative flex w-full rounded-b-lg border-0 bg-cool-gray-200" +
                  " px-2.5 py-4 text-left text-base text-indigo-500 outline-none" +
                  " placeholder:text-cool-gray-900/60 focus:bg-cool-gray-300" +
                  " rounded-t-0 focus:ring-2 focus:ring-cerulean-500 focus:placeholder:text-cool-gray-900/80" +
                  (hasPasswordValidationError
                    ? " ring-2 ring-raspberry-500"
                    : "")
                }
                type={showConfirmPwd ? "text" : "password"}
                placeholder="Confirm Password"
                value={confirmPass}
                ref={confirmNewPasswordRef}
                onChange={(e) => {
                  setConfirmPass(e.target.value);
                  setHasPasswordValidationError(false);
                }}
              />
              <div className="absolute right-1.5 top-4">
                <div
                  className="flex items-center justify-center"
                  role="button"
                  onClick={handleConfirmPwdVisibility}
                >
                  {confirmPass ? (
                    showConfirmPwd ? (
                      <img
                        src={eyeShowIcon}
                        alt="Show Password"
                        loading="lazy"
                        className="w-[22px] cursor-pointer text-cerulean-500"
                      />
                    ) : (
                      <img
                        src={eyeHideIcon}
                        alt="Hide Password"
                        loading="lazy"
                        className="w-[22px] cursor-pointer text-cerulean-500"
                      />
                    )
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
            {!passwordValidError && (
              <label className="text-sm font-normal text-cool-gray-800">
                Password requires: 8-16 characters, 1 uppercase letter, 1
                lowercase letter, and 1 number.
              </label>
            )}
            {passwordValidError && (
              <InlineErrorMessage errorMessage={passwordValidError} />
            )}
            {newPasswordError && (
              <InlineErrorMessage errorMessage={newPasswordError} />
            )}
            {loginError && <InlineErrorMessage errorMessage={loginError} />}
          </div>
        </div>
        <Button
          id="change_password_btn"
          loading={isCallingAPI}
          disabled={
            (currentPass.length || firebaseFlags.firebaseAuth) &&
            password.length &&
            confirmPass.length
              ? false
              : true
          }
          btnType="green"
          onClick={
            firebaseFlags.firebaseAuth
              ? handleFirebaseChangePassword
              : handleChangePassword
          }
        >
          Change Password
        </Button>
      </div>
    </ModalContainer>
  );
};

export default ChangePasswordModal;
