// Components //
import Video from "../../common/Video";
import TextWithContentSplit from "../../common/TextWithContentSplit";

// Assets //
import ThumbWhyWeCharge from "../../../global-assets/assets/thumbnail_why_we_charge.webp";
import LargePlayBtn from "../../crowdfunding/assets/branded_play_btn.svg";

export default function AboutWhyWeCharge() {
  return (
    <TextWithContentSplit
      headerType="h2"
      headerText="Why do we charge for a subscription?"
      subtext={`We charge a subscription fee to bring you high-quality Christian 
    games, videos, and stories, prioritizing your child's safety. Plus, 
    with just one fee, you won't have to worry about any extra charges 
    or add-ons.`}
      content={
        <Video
          id="why_we_charge_video"
          videoId={920536083}
          url="https://truplay-videos.sfo3.cdn.digitaloceanspaces.com/why_we_charge_a_subscription.%20(720p).mp4"
          shareUrl="https://vimeo.com/920536083"
          shareSmall
          controls
          playsInline
          thumbnail={ThumbWhyWeCharge}
          customPlayBtn={LargePlayBtn}
        />
      }
      subtextColor="text-white"
      contentLocation="left"
      textPositionOnMobile="above"
    />
  );
}
