// Components //
import Video from "../common/Video";
import HeroVisualTextSection from "../common/HeroVisualTextSection";

export default function SupportTheMission() {
  const openKickstarterPage = () => {
    window.open(
      "https://www.kickstarter.com/projects/truplaygames/truplay-bible-gaming-app-for-children?ref=w6gohd",
      "_blank"
    );
  };

  const contentText = (
    <p className="m-0 text-lg text-white lg:text-xl">
      TruPlay plans to reverse the negative trends impacting kids in the digital
      age by meeting them where they are with content that builds character and
      nurtures faith. Our Kickstarter is LIVE!
      <br />
      <br />
      The first 48 hours are crucial to the success of a Kickstarter campaign.
      Please click below and consider backing our mission. Thank you!
    </p>
  );

  const video = (
    <Video
      id="video_support_out_mission"
      videoId="O7xCG6j8sbw"
      url="O7xCG6j8sbw"
      title="We're Bringing it BACK. Journey of Jesus. We Need Your Help."
      playsInline
      embedded="youTube"
    />
  );

  return (
    <HeroVisualTextSection
      sectionId="support_the_mission"
      headerText="Support The Mission"
      headerStyles="relative text-3xl sm:text-4xl md:text-5xl lg:inline lg:max-w-[250px] lg:text-4xl 2xl:max-w-[425px] 2xl:text-[2.3rem]"
      contentText={contentText}
      visualComponent={video}
      visualPosition="left"
      ctaText="Visit Kickstarter Page"
      ctaAction={openKickstarterPage}
    />
  );
}
