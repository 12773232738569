import { useState } from "react";
import { ClearAllCookies, navigateTo } from "../../../config/UserAuthContext";
import { GetContinueToQueryString } from "../../../lib/PassedParams";
import TruPlayLogoDark from "../../../global-assets/assets/truplay-logo-dark.svg";
import CloseIconDark from "../../../global-assets/assets/icon-close-indigo.svg";
import Button from "../../common/Button";

interface MobileLeftNavMenuProps {
  isAuthenticated: any;
  closeMenu: Function;
  isOpen: boolean;
  menuRef: any;
}

const mobileDropdownItemStyles =
  "hover:bg-dropdown_active whitespace-nowrap bg-cool-gray-200 px-6 py-4 text-left" +
  " text-lg font-normal leading-6 text-indigo-500 hover:text-white";

export default function MobileLeftNavMenu({
  isAuthenticated,
  closeMenu,
  isOpen,
  menuRef,
}: MobileLeftNavMenuProps) {
  const [isMoreMenuOpen, setMoreMenuOpen] = useState<boolean>(false);

  const handleTeamLinkClick = () => {
    if (window.location.pathname === "/about") {
      const section = document.getElementById("team-section");
      if (section) {
        section.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      navigateTo("/about#team-section");
    }
  };

  const logIn = () => {
    navigateTo(`/login${GetContinueToQueryString()}`);
  };

  const logOut = () => {
    ClearAllCookies();
    navigateTo(`/login${GetContinueToQueryString()}`);
  };

  return (
    <div
      ref={menuRef}
      className={
        "absolute left-0 top-0 z-[1000] hidden min-w-[18rem] rounded-ee-3xl bg-white shadow-xl" +
        (isOpen ? " !block lg:hidden" : "")
      }
    >
      <div className="flex h-[104px] items-center px-4 py-6">
        <div
          role="button"
          id="mobile_nav_icon_close_light"
          className="-mb-1 -ml-2 mr-2 flex w-[56px] cursor-pointer items-center justify-center sm:mr-4"
          onClick={() => closeMenu(false)}
        >
          <img src={CloseIconDark} loading="lazy" alt="" className="w-[30px]" />
        </div>
        <div className="cursor-pointer" onClick={() => navigateTo("/")}>
          <img
            src={TruPlayLogoDark}
            alt="Truplay-logo"
            className="w-[7.5rem] sm:w-[9.5rem] lg:w-48"
            id="mobile_unauth_tru_logo_light"
          />
        </div>
      </div>
      <div>
        <div className="flex justify-center px-6 py-4">
          <Button
            id="give_as_gift_btn_light"
            onClick={() => {
              navigateTo("/gift");
            }}
            btnType="secondaryAlt"
            className="w-full !py-3 active:!text-white"
          >
            Give as Gift
          </Button>
        </div>
        <div
          id="mobile_kickstarter_link_light"
          role="button"
          onClick={() => navigateTo("/support-our-mission")}
          className={
            "flex cursor-pointer items-center justify-start p-6 font-display text-xl font-bold leading-5 tracking-wide text-indigo-500 hover:bg-dropdown_active hover:!text-white" +
            (window.location.pathname.indexOf("/support-our-mission") !== -1
              ? " pointer-events-none bg-menu_active !text-cerulean-500"
              : "")
          }
        >
          Kickstarter
        </div>
        <div
          id="mobile_games_more_link_light"
          role="button"
          onClick={() => navigateTo("/games")}
          className={
            "flex cursor-pointer items-center justify-start p-6 font-display text-xl font-bold leading-5 tracking-wide text-indigo-500 hover:bg-dropdown_active hover:!text-white" +
            (window.location.pathname.indexOf("/games") !== -1
              ? " pointer-events-none bg-menu_active !text-cerulean-500"
              : "")
          }
        >
          Games
        </div>
        <div
          id="mobile_about_dropdown_light"
          role="button"
          onClick={() => setMoreMenuOpen(!isMoreMenuOpen)}
          className={
            "group relative flex cursor-pointer items-center justify-start p-6 font-display text-xl font-bold leading-5 tracking-wide text-indigo-500 hover:bg-menu_active hover:!text-cerulean-500" +
            (isMoreMenuOpen ? " bg-menu_active !text-cerulean-500" : "")
          }
        >
          <div>More</div>
          <div className="absolute right-4 flex w-[56px] items-center justify-center">
            <svg
              fill="none"
              width="56"
              height="56"
              viewBox="0 0 56 56"
              xmlns="http://www.w3.org/2000/svg"
              className="w-[28px]"
            >
              <path
                className={
                  "group-hover:stroke-cerulean-500" +
                  (isMoreMenuOpen
                    ? " stroke-cerulean-500"
                    : " stroke-indigo-500")
                }
                d="M4 15L28 41L52 15"
                strokeWidth="8"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        </div>
        {isMoreMenuOpen && (
          <div className="relative flex max-h-[200px] w-full cursor-pointer flex-col flex-nowrap justify-start overflow-y-scroll bg-cool-gray-200">
            <a
              id="mobile_merchandise_link_light"
              href="https://truplaygames.shop/"
              rel="noreferrer"
              target="_blank"
              className={mobileDropdownItemStyles}
            >
              Merchandise
            </a>
            <div
              id="mobile_free_content_link_light"
              role="button"
              onClick={() => navigateTo("/free-content")}
              className={
                mobileDropdownItemStyles +
                (window.location.pathname.indexOf("/free-content") !== -1
                  ? " pointer-events-none bg-dropdown_active"
                  : "")
              }
            >
              Free Content
            </div>
            <div
              id="mobile_who_we_are_link_light"
              role="button"
              onClick={() => navigateTo("/about")}
              className={
                mobileDropdownItemStyles +
                (window.location.pathname.indexOf("/about") !== -1
                  ? " pointer-events-none bg-dropdown_active"
                  : "")
              }
            >
              About Us
            </div>
            <a
              id="mobile_news_link_light"
              href="https://www.truplaygames.com/resources/news"
              rel="noreferrer"
              target="_blank"
              className={mobileDropdownItemStyles}
            >
              News
            </a>
            <div
              id="mobile_affiliate_link_light"
              role="button"
              onClick={() => navigateTo("/affiliate")}
              className={
                mobileDropdownItemStyles +
                (window.location.pathname === "/affiliate"
                  ? " pointer-events-none bg-dropdown_active"
                  : "")
              }
            >
              Refer A Friend
            </div>
            <div
              id="mobile_contact_link_light"
              role="button"
              onClick={() => navigateTo("/contact")}
              className={
                mobileDropdownItemStyles +
                (window.location.pathname.indexOf("/contact") !== -1
                  ? " pointer-events-none bg-dropdown_active"
                  : "")
              }
            >
              Contact
            </div>
          </div>
        )}
        <div className="border-t-solid border-t-solid group flex cursor-pointer items-center justify-start rounded-ee-3xl border-t-2 border-t-cool-gray-300 p-6 hover:bg-dropdown_active">
          <div
            id="mobile_log_in_out_btn_light"
            role="button"
            className="font-display text-xl font-bold leading-5 tracking-wide text-cerulean-500 group-hover:!text-white"
            onClick={isAuthenticated ? () => logOut() : () => logIn()}
          >
            {isAuthenticated ? "Log Out" : "Log In"}
          </div>
        </div>
      </div>
    </div>
  );
}
