import { PulseLoader } from "react-spinners";

interface IButtonProps {
  id?: string;
  children?: any;
  onClick?: Function;
  // Options: Green, Orange, Blue, Purple, Red, Secondary, SecondaryAlt, Tertiary, TertiaryAlt
  // See https://www.figma.com/design/tXH75MyaPRPLOWT5YkzOMh/APP_Components?node-id=11709-481220&t=NV9s26xvH8PgmEDR-0 for examples
  btnType?: string;
  disabled?: boolean;
  leftIcon?: JSX.Element;
  rightIcon?: JSX.Element;
  loading?: boolean;
  formType?: "button" | "submit" | "reset";
  className?: string; // Any extra styles to override
}

export default function Button({
  id,
  children,
  onClick,
  btnType,
  disabled,
  leftIcon,
  rightIcon,
  loading,
  formType,
  className,
}: IButtonProps) {
  const defaultBtnStyles =
    "flex cursor-pointer items-center justify-center rounded-full px-6 py-3.5 font-display font-bold tracking-wide duration-300 hover:-translate-y-[3px] hover:shadow-[0_6px_20px_rgba(56,166,213,0.4)] active:scale-105 active:font-extrabold active:tracking-wider active:duration-100 text-lg md:text-xl shadow-[0px_12px_32px_-16px_#001b36] ";
  const disabledBtnStyles = "opacity-50 pointer-events-none !shadow-none";

  let type = "";
  switch (btnType) {
    case "green":
      type =
        "text-white bg-[linear-gradient(270deg,#38D58A_0.09%,#22A3A1_99.91%)] active:bg-[linear-gradient(to_right,#38D58A_0%,#38D58A_100%)]";
      break;
    case "orange":
      type =
        "text-white bg-[linear-gradient(270deg,#FFA300_0%,#A84AD4_100%)] active:bg-[linear-gradient(to_right,#FFA300_0%,#FFA300_100%)]";
      break;
    case "blue":
      type =
        "text-white bg-[linear-gradient(90deg,#38A6D5_0%,#7AC9C5_100%)] active:bg-[linear-gradient(to_right,#7AC9C5_0%,#7AC9C5_100%)]";
      break;
    case "purple":
      type =
        "text-white bg-[linear-gradient(90deg,#652C80_0%,#A84AD4_101.39%)] active:bg-[linear-gradient(to_right,#A84AD4_0%,#A84AD4_100%)]";
      break;
    case "red":
      type =
        "text-white bg-[linear-gradient(90deg,#8C2A6D_0%,#D0274B_100%)] active:bg-[linear-gradient(to_right,#D0274B_0%,#D0274B_100%)]";
      break;
    case "secondary":
      type =
        "text-white bg-none border-[3px] border-solid border-cerulean-500 active:bg-turquoise-500 active:border-turquoise-500 hover:bg-turquoise-500/30";
      break;
    case "secondaryAlt":
      type =
        "text-cerulean-500 bg-none border-[3px] border-solid border-cerulean-500 active:bg-turquoise-500 active:border-turquoise-500 hover:bg-turquoise-500/30 active:text-white";
      break;
    case "tertiary":
      type = "text-white hover:drop-shadow-[0px_0px_16px_#7AC9C5]";
      break;
    case "tertiaryAlt":
      type =
        "text-cerulean-500 hover:drop-shadow-[0px_0px_16px_#6ABDE0] active:text-white";
      break;
    case "googleSSO":
      type =
        "!font-googleSSO !rounded-[42.667px] border-[3px] border-solid border-[#8E918F] bg-[#131314] gap-4 text-[#E3E3E3] font-medium text-base regmd:h-14 regmd:!text-xl hover:!shadow-none active:!scale-100 active:!font-medium";
      break;
  }

  if (btnType?.indexOf("tertiary") !== -1) {
    return (
      <div
        id={id}
        role={formType}
        className={
          "flex cursor-pointer flex-row items-center font-display text-lg font-bold tracking-[0.45px] duration-200 active:scale-105 active:font-extrabold active:tracking-[0.5px] active:duration-0 md:text-xl " +
          type +
          (disabled ? " " + disabledBtnStyles : "") +
          (className ? " " + className : "")
        }
        onClick={(e: any) =>
          typeof onClick === "function" ? onClick(e) : null
        }
      >
        {leftIcon && !loading ? leftIcon : null}
        {loading ? <PulseLoader color="#ffffff" /> : children}
        {rightIcon && !loading ? rightIcon : null}
      </div>
    );
  }

  return (
    <button
      id={id}
      className={
        defaultBtnStyles +
        type +
        (disabled ? " " + disabledBtnStyles : "") +
        (className ? " " + className : "") +
        (loading ? " h-[52px] sm:h-[56px]" : "")
      }
      disabled={disabled || loading}
      onClick={(e: any) => (typeof onClick === "function" ? onClick(e) : null)}
      type={formType}
    >
      {leftIcon ? leftIcon : null}
      {loading ? <PulseLoader color="#ffffff" /> : children}
      {rightIcon ? rightIcon : null}
    </button>
  );
}
