export interface ErrorObj {
  [key: string]: string;
}

export class PasswordValidator {
  private readonly secret: string = "";
  public readonly test: boolean = false;
  error: string = "";

  constructor(_password: string, _isTest?: boolean) {
    this.secret = _password;
    this.test = _isTest || false;
    this.validate();
  }

  meetsPasswordComplexityRule = (password: string, isTest: boolean): boolean => {
    if (isTest) {
      const isLengthValid = password.length >= 6; // Adjust as needed
      return isLengthValid;
    }

    // Define regular expressions for complexity requirements
    const hasUppercase = /[A-Z]/.test(password);
    const hasLowercase = /[a-z]/.test(password);
    const hasDigit = /[0-9]/.test(password);
    //const hasSpecialChar = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(this.secret);

    // Define password length requirement
    const isLengthValid = password.length >= 8; // Adjust as needed

    // Check all complexity requirements
    return hasUppercase && hasLowercase && hasDigit && isLengthValid;
  };

  validate = () => {
    if (!this.secret) {
      this.error = "Valid password is required";
    } else if (!this.meetsPasswordComplexityRule(this.secret, this.test)) {
      if (this.test) {
        this.error = "Passwords must be at least 6 characters."
      } else {
        this.error =
          "Password requires: 8-16 characters, 1 uppercase letter, 1 lowercase letter, and 1 number.";
      }
    }
    return;
  };

  get ErrorMessage(): string {
    return this.error;
  }
}

export class EmailValidator {
  private readonly emailAddress: string = "";
  error: string = "";

  constructor(email: string) {
    this.emailAddress = email;
    this.validate();
  }

  /**
   * Validates standard email format
   * Accepts up to 10 character top level domain
   *
   * Valid:
   * a_b@example.com
   * a.b@example.co
   * a.b+test@example.com
   * a_b+test@example.business
   *
   * https://regex101.com/
   */
  validate = () => {
    let emailRegex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/gm;

    if (!this.emailAddress) {
      this.error = "Valid email address is required";
    } else if (!emailRegex.test(this.emailAddress)) {
      this.error = "Email address format is not supported";
    }
    return;
  };

  get ErrorMessage(): string {
    return this.error;
  }
}

export default EmailValidator;
