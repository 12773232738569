// Components //
import SectionHeader from "../common/SectionHeader";
import FeaturedCarousel from "../common/FeaturedCarousel";
import Button from "../common/Button";

// Assets //
import JOJImage1 from "./assets/Sea-of-Galilee.webp";
import JOJImage2 from "./assets/JOJ_Style_env_02.webp";
import JOJImage3 from "./assets/JOJ_env_03_Wedding_at_Cana.webp";
import JOJImage4 from "./assets/JOJ_env_03_Wedding_at_Cana_Indoor_A.webp";
import JOJImage5 from "./assets/JOJ_env_03_Wedding_at_Cana_Indoor_B.webp";

interface ITileList {
  id: string;
  tileImage: string; // Path to tile image
  component: JSX.Element | null;
  focus: boolean; // True if the carousel has focused tile logic
  type: string; // Type of tile
  visible: boolean; // True if within the container of visibility
  display: JSX.Element | null; // Element displayed when featured
}

const tileList: ITileList[] = [
  {
    id: "tile_1",
    tileImage: JOJImage1,
    component: null,
    focus: false,
    type: "image",
    display: null,
    visible: true,
  },
  {
    id: "tile_2",
    tileImage: JOJImage2,
    component: null,
    focus: false,
    type: "image",
    display: null,
    visible: true,
  },
  {
    id: "tile_3",
    tileImage: JOJImage3,
    component: null,
    focus: false,
    type: "image",
    display: null,
    visible: true,
  },
  {
    id: "tile_4",
    tileImage: JOJImage4,
    component: null,
    focus: false,
    type: "image",
    display: null,
    visible: true,
  },
  {
    id: "tile_5",
    tileImage: JOJImage5,
    component: null,
    focus: false,
    type: "image",
    display: null,
    visible: false,
  },
];

tileList.forEach((tile) => {
  tile.component = (
    <img
      src={tile.tileImage}
      alt={tile.id}
      loading="eager"
      draggable="false"
      className="rounded-lg sm:rounded-2xl lg:rounded-[20px]"
    />
  );
  tile.display = (
    <div
      id="tru-feature_bringing_joj_back"
      key={tile.id}
      className="w-full rounded-[40px] bg-white/[6%] p-3 align-middle md:p-4 lg:p-5"
    >
      <img
        src={tile.tileImage}
        alt={tile.id}
        loading="eager"
        draggable="false"
        className="rounded-[30px]"
      />
    </div>
  );
});

export default function BringingJOJBack() {
  const tileMargin = 16;
  const carouselPaddingTop = 0;
  const carouselPaddingBottom = 32;

  const openKickstarterPage = () => {
    window.open(
      "https://www.kickstarter.com/projects/truplaygames/truplay-bible-gaming-app-for-children?ref=w6gohd",
      "_blank"
    );
  };

  const ctaSection = (view: string) => (
    <div
      className={
        "mb-8 w-full flex-1 flex-col gap-6 rounded-[40px] bg-white/[6%] px-6 py-8 shadow-[0px_0px_80px_0px_#001B36] lg:flex-[0.3_1_0] lg:px-12 lg:py-16" +
        (view === "mobile"
          ? " flex lg:hidden"
          : " hidden justify-between lg:flex")
      }
    >
      <div className="flex flex-col gap-6">
        <h3 className="text-transparent bg-gradient_text bg-clip-text text-center text-4xl !leading-[2.75rem] lg:text-left">
          Support the Development of Journey of Jesus
        </h3>
        {/* TODO: I'm going to bet that we will be asked to remove the outer quotations - Tim 07/15/2024 */}
        <p className="m-0 text-center text-lg font-normal !leading-8 text-white lg:text-left lg:text-xl lg:font-medium">
          "This is where the mission gets personal. With your help, we will be
          able to resurrect the "Journey of Jesus" for the next generation.
          Millions of families around the world can experience the Truth of the
          Bible unlike anything before!"
        </p>
        <p className="m-0 text-center text-lg font-normal !leading-8 text-indigo-200 lg:text-left lg:text-xl lg:font-medium">
          Estimate Budget for JOJ: $500K
        </p>
      </div>
      <Button
        id="support_joj_btn"
        btnType="green"
        onClick={openKickstarterPage}
        className="w-full"
      >
        Visit Kickstarter Page
      </Button>
    </div>
  );

  return (
    <div className="flex flex-col gap-6 lg:gap-12">
      <SectionHeader
        headerType="h2"
        headerText="We're Bringing Journey of Jesus Back!"
        starLocation="right"
      />
      <div className="flex flex-col justify-center gap-8 lg:flex-row lg:gap-10">
        <div className="w-full flex-1 overflow-hidden lg:flex-[0.7_1_0]">
          <FeaturedCarousel
            id="bringing_joj_back"
            initialState={tileList}
            cardMargin={tileMargin}
            responsive
            animated
            cardStyles="shadow-lg rounded-lg sm:rounded-2xl lg:rounded-[20px] cursor-pointer duration-200 ease-in-out"
            hoverStyles="md:group-hover/card-hover:shadow-[0_10px_15px_-3px_rgba(56,166,213,0.60),0_4px_6px_-8px_#38A6D5] md:group-hover/card-hover:ring-white md:group-hover/card-hover:ring-4"
            focusStyles="rounded-lg sm:rounded-2xl lg:rounded-[20px] ring-[3px] sm:ring-4 ring-white cursor-pointer duration-200 ease-in-out shadow-[0_10px_15px_-3px_rgba(56,166,213,0.60),0_4px_6px_-8px_#38A6D5]"
            arrowType="featured"
            arrowCornerType="rounded"
            selection
            paddingBottom={carouselPaddingBottom}
            paddingTop={carouselPaddingTop}
            focus
            visibleCount={4}
          />
        </div>
        {ctaSection("desktop")}
      </div>
      <div className="flex flex-1 flex-col gap-4">
        <h3 className="text-center text-4xl text-white lg:text-left">
          Journey of Jesus{" "}
          <span className="font-body text-2xl font-medium lg:text-4xl">
            (Est. Budget $500K)
          </span>
        </h3>
        <p className="m-0 text-center text-lg font-normal text-indigo-200 lg:text-left lg:text-xl lg:font-medium">
          Journey of Jesus was one of the most impactful Christian games in
          history. 25,000 people said the game helped them learn about the life
          of Jesus and contributed to their salvation decision. After an abrupt
          end due to the end of Flash support, we had millions of players
          clamoring for us to bring it back and asking how they could support
          such an initiative. Well - we heard you and plan to bring Journey of
          Jesus back! Yes, this is for real!
        </p>
      </div>
      {ctaSection("mobile")}
    </div>
  );
}
