interface IBlurredSectionProps {
  children?: any;
  rounded?: boolean;
  fullWidth?: boolean;
}

export default function BlurredSection({
  children,
  rounded,
  fullWidth,
}: IBlurredSectionProps) {
  return (
    <div
      className={
        "relative overflow-hidden bg-white/5 p-6 md:px-12 md:py-8" +
        (rounded ? " rounded-3xl" : "") +
        (fullWidth ? " py-16 shadow-[0_0_80px_0_#001b36] md:!py-24" : "")
      }
    >
      {children}
    </div>
  );
}
