import curvewhite from "../global-assets/assets/curvewhite.svg";
import curveblue from "../global-assets/assets/curveblue.svg";
import teambg from "../global-assets/assets/teambg.webp";
import Icon1 from "../global-assets/assets/Signup.webp";
import Icon2 from "../global-assets/assets/Recommend.webp";
import Icon3 from "../global-assets/assets/Earn.webp";
import contactherobg from "../global-assets/assets/contactherobg.webp";

export const MarketingContactEmails = {
  images: {
    curvewhite,
    contactherobg,
    curveblue,
    teambg,
  },
};

export const AffiliateCardsData = [
  {
    icon: Icon1,
    title: "Sign Up",
    description:
      "By following the link, You'll receive a confirmation email shortly after.",
  },
  {
    icon: Icon2,
    title: "Recommend",
    description:
      "Share TruPlay with your community and help spread faith while making money. Access customized linking tools and assets to help you succeed.",
  },
  {
    icon: Icon3,
    title: "Earn",
    description:
      "Earn commissions effortlessly and track your earnings with our intuitive dashboard.",
  },
];

export const AffiliateTestimonialData = [
  {
    title: "Family friendly FUN",
    description:
      "This amazing app is a MUST HAVE for parents and grandparents who are concerned about the content that children are being exposed to through digital games and entertainment. Kids will absolutely LOVE this platform!!",
    initials: "J",
  },
  {
    title: "FINALLY something my kids can play!!!",
    description:
      "As a parent I'm SO excited to see this!!! Games my kids can play that are safe for them! These games are really cool too, not cheesy or low quality and my kids keep asking to play ALL THE TIME. THANK YOU for making these games!!!",
    initials: "G",
  },
  {
    title: "Not just for kids",
    description:
      "Downloaded app and it's good as they have games for the older folk like me. Stained glass is a match 3 game that is at my pace. Also, they have a word game called Lumi's word of light which is very enjoyable and challenging.",
    initials: "L",
  },
];
