import { useContext, useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import ApiFunction from "../../../lib/ApiFunction";
import Cookies from "js-cookie";
import { Context, navigateTo } from "../../../config/UserAuthContext";
import EmailValidator, {
  PasswordValidator,
} from "../../../config/UserInputValidator";
import { InlineErrorMessage } from "../../notification/InlineErrorMessage/InlineErrorMessage";
import { ContinueTo } from "../../../lib/PassedParams";
import TagManager from "react-gtm-module";
import { GetOrCreateSessionAuthContext } from "../../../config/UserAuthContext";
import {
  MixPanelAnalyticsContext,
  Properties,
} from "../../analytics/Analytics";
import eyeShowIcon from "../../../global-assets/assets/icon-eye-show.svg";
import eyeHideIcon from "../../../global-assets/assets/icon-eye-hide.svg";
import Button from "../../common/Button";

/**
 * Login Page to authenticate users with valid email address on file
 *
 * @param props
 * @constructor
 */
interface RegistraionFormProps {
  setAuth: any;
  setLoginData: any;
  freeTrial?: boolean;
  pageDetails?: {
    pageName: string;
    pageType: string;
  };
  ctaText: string;
}

export default function RegistraionForm({
  setAuth,
  setLoginData,
  freeTrial,
  pageDetails,
  ctaText,
}: RegistraionFormProps) {
  const analyticsContext = useContext(MixPanelAnalyticsContext);
  const { AccountServiceRegistration, EnforcePasswordRules } = ApiFunction();

  interface ErrorObj {
    [key: string]: string;
  }

  // Attributes bound to state
  const [email, setEmail] = useState<string>("");
  const [emailError, setEmailError] = useState<string | null>(null);
  const [password, setPassword] = useState<string>("");
  const [passwordError, setPasswordError] = useState<string | null>(null);
  const [hasPasswordValidationError, setHasPasswordValidationError] =
    useState<boolean>(false);
  const [hasEmailValidationError, setEmailValidationError] =
    useState<boolean>(false);

  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [isCallingAPI, setIsCallingAPI] = useState<boolean>(false);
  const [communicationOptin, setCommunicationOptin] = useState<boolean>(false);
  const [enabledCreateAccountButton, setEnabledCreateAccountButton] =
    useState<boolean>(false);

  const [acceptsTosPrivacyPolicy, setAcceptsTosPrivacyPolicy] =
    useState<boolean>(false);

  const emailRef = useRef<any>(null);
  const passwordRef = useRef<any>(null);

  const userContext = GetOrCreateSessionAuthContext();
  const GlobalUserId = Cookies.get("GlobalUserId");
  const osType = navigator.userAgent;

  const subscriptionInfo = userContext.subscription.sources;

  useEffect(() => {
    if (email && password.length > 0 && acceptsTosPrivacyPolicy) {
      setEnabledCreateAccountButton(true);
    } else {
      setEnabledCreateAccountButton(false);
    }
  }, [email, password, acceptsTosPrivacyPolicy]);

  useEffect(() => {
    if (emailRef.current?.value) setEmail(emailRef.current?.value);
    if (passwordRef.current?.value) setPassword(passwordRef.current?.value);

    // We do not want a re-render from missing deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleAcceptTermsOfService = (event: { target: { checked: any } }) => {
    setCommunicationOptin(event.target.checked);
    setAcceptsTosPrivacyPolicy(event.target.checked);
  };

  const validateRegistrationCredentials = () => {
    let errors: ErrorObj = {};

    let emailValidator = new EmailValidator(email);
    if (emailValidator.ErrorMessage) {
      errors.email = emailValidator.ErrorMessage;
      setEmailValidationError(true);
    }

    let passwordValidator = new PasswordValidator(password);
    if (passwordValidator.ErrorMessage) {
      errors.password = passwordValidator.ErrorMessage;
      setHasPasswordValidationError(true);
    }

    EnforcePasswordRules.post("", { password: password })
      .then((response) => {
        if (!response.data.isValid) {
          errors.password =
            "Password requires: 8-16 characters, 1 uppercase letter, 1 lowercase letter, and 1 number.";
          setEmailError(errors.email);
          setPasswordError(errors.password);
        }
      })
      .catch((error) => {});

    setEmailError(errors.email);
    setPasswordError(errors.password);
    return errors;
  };

  /**
   * Function to process response on successful account creation
   * @param response
   */
  let onSuccessfulAccountCreation = (
    response: any,
    pageDetails?: { pageName: string; pageType: string }
  ) => {
    let { uuId, token } = response.data;

    Cookies.set(
      Context.AuthContext,
      JSON.stringify({ userID: uuId, token: token })
    );

    Cookies.set(Context.UserContext, JSON.stringify(response.data));

    Cookies.set("EmailAddress", email);

    setAuth(true);
    setLoginData(response.request.data);

    if (pageDetails) {
      TagManager.dataLayer({
        dataLayer: {
          event: "sign_up",
          plan_type: subscriptionInfo[0] || "none",
          os_type: osType,
          method: "email",
          page_name: pageDetails.pageName,
          page_type: pageDetails.pageType,
          custom_user_id: GlobalUserId || undefined,
        },
      });
    }

    //
    let attribution = localStorage.getItem("tru-attribution") as string;
    let campaignAttribution: Properties = {};
    if (attribution) {
      campaignAttribution = JSON.parse(attribution) as Properties;
    }

    analyticsContext.track(
      "Web: Account-Creation-Successful",
      campaignAttribution
    );
    // look for continueto param, go there instead if we have one
    if (ContinueTo()) {
      analyticsContext.track(
        "Web: Redirect-After-Account-Creation",
        campaignAttribution
      );
      navigateTo(ContinueTo() || "");
    } else {
      analyticsContext.track(
        "Web: Select-Plan-After-Account-Creation-Viewed",
        campaignAttribution
      );
      TagManager.dataLayer({
        dataLayer: {
          event: "select_content",
          content_type: "link",
          os_type: osType,
          link_name: "Create Account",
          link_url: "/select-plan",
          plan_type: subscriptionInfo[0] || "none",
          custom_user_id: GlobalUserId || undefined,
        },
      });
      navigateTo("/select-plan");
    }
  };

  /**
   * Create new Truplay Account
   * @param e
   */
  let createNewAccount = (e: any) => {
    setHasPasswordValidationError(false);
    setEmailValidationError(false);

    let credentialValidationError = validateRegistrationCredentials();
    setEmailError(credentialValidationError.email);
    setPasswordError(credentialValidationError.password);

    if (Object.keys(credentialValidationError).length > 0) {
      return;
    }

    setIsCallingAPI(true);

    let data = {
      emailAddress: email,
      password: password,
      communicationsOptIn: communicationOptin,
    };

    AccountServiceRegistration.post("", data)
      .then((response) => {
        onSuccessfulAccountCreation(response, pageDetails);
      })
      .catch((error) => {
        setIsCallingAPI(false);

        setPasswordError(error.response.data.statusMessage);
        if (error.response.data.statusCode === "AS005") {
          toast.error(
            "There was an error creating an account with this email address."
          );
          analyticsContext.track("Web: Account-Creation-Failed-Existing-Email");
        }

        if (error.response.data.statusCode === "AS020") {
          analyticsContext.track(
            "Web: Account-Creation-Failed-Invalid-Credentials"
          );
          let errors: ErrorObj = {};
          errors.email = error.response.data.statusMessage;
          setEmailValidationError(true);
          setEmailError(errors.email);
          setPasswordError(errors.password);
        }
      });
  };

  return (
    <div className="flex flex-col gap-6">
      <div id="user-credentials-container">
        <div className="relative mb-2 flex w-full flex-col gap-1">
          <div className="relative flex w-full">
            <input
              id="user-email-input"
              className={
                "relative flex w-full rounded-t-lg border-0 bg-black/25" +
                " px-2.5 py-4 text-left text-base text-white/60 outline-none" +
                " placeholder:text-white/60 focus:bg-black/25" +
                " rounded-b-0 focus:ring-2 focus:ring-cerulean-500 focus:placeholder:text-white/80" +
                (hasEmailValidationError ? " ring-2 ring-raspberry-500" : "")
              }
              ref={emailRef}
              placeholder="Email"
              value={email}
              onChange={(e: any) => {
                setEmail(e.target.value);
                setEmailValidationError(false);
              }}
            />
          </div>
          <div className="relative flex w-full">
            <input
              id="user-password-input"
              className={
                "relative flex w-full rounded-b-lg border-0 bg-black/25" +
                " px-2.5 py-4 text-left text-base text-white/60 outline-none" +
                " placeholder:text-white/60 focus:bg-black/25" +
                " rounded-t-0 focus:ring-2 focus:ring-cerulean-500 focus:placeholder:text-white/80" +
                (hasPasswordValidationError ? " ring-2 ring-raspberry-500" : "")
              }
              ref={passwordRef}
              type={showPassword ? "text" : "password"}
              placeholder="Password"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
                // enable only when privacy policy has been accepted
                setEnabledCreateAccountButton(acceptsTosPrivacyPolicy);
                setHasPasswordValidationError(false);
                setPasswordError(null);
              }}
            />
            <div className="absolute right-1.5 top-4">
              <div
                className="flex items-center justify-center"
                role="button"
                onClick={handlePasswordVisibility}
              >
                {password ? (
                  showPassword ? (
                    <img
                      src={eyeShowIcon}
                      alt="Show Password"
                      loading="lazy"
                      className="w-[22px] cursor-pointer text-cerulean-500"
                    />
                  ) : (
                    <img
                      src={eyeHideIcon}
                      alt="Hide Password"
                      loading="lazy"
                      className="w-[22px] cursor-pointer text-cerulean-500"
                    />
                  )
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="flex justify-center text-center text-base text-indigo-200">
          Passwords must be between 8 and 16 characters, include 1 uppercase
          letter, <br />1 lowercase letter and 1 number
        </div>

        {emailError || passwordError ? (
          <div>
            {emailError && <InlineErrorMessage errorMessage={emailError} />}
            {passwordError && (
              <InlineErrorMessage errorMessage={passwordError} />
            )}
          </div>
        ) : null}
      </div>

      <div className="flex items-center justify-center gap-2 lg:justify-start">
        <input
          id="terms-checkbox"
          type="checkbox"
          onChange={toggleAcceptTermsOfService}
        />
        <label
          className="relative font-body text-base font-medium text-indigo-100"
          htmlFor="terms-checkbox"
        >
          I accept{" "}
          <a
            rel="noreferrer"
            href="/terms-and-conditions"
            target="_blank"
            className="font-body font-bold text-cerulean-500 underline"
          >
            Terms of Service
          </a>{" "}
          &{" "}
          <a
            rel="noreferrer"
            href="/privacy-policy"
            target="_blank"
            className="font-body font-bold text-cerulean-500 underline"
          >
            Privacy Policy
          </a>
          <span className="absolute -right-[8px] -top-[4px] text-xl text-raspberry-500">
            *
          </span>
        </label>
      </div>
      <div className="flex flex-col justify-center gap-4">
        <Button
          id="registration_page_submit"
          loading={isCallingAPI}
          disabled={!enabledCreateAccountButton}
          onClick={createNewAccount}
          btnType="green"
          className="w-full"
        >
          {ctaText}
        </Button>
      </div>
      <div className="flex items-center justify-center gap-2 text-center text-white">
        <p className="m-0 text-base text-white">Already have an account?</p>
        <Button
          id="log_in_inline_reg_btn"
          btnType="tertiaryAlt"
          className="!text-base hover:underline active:!text-cerulean-500"
        >
          Log In
        </Button>
      </div>
    </div>
  );
}
