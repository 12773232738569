import { useEffect } from "react";
import { Helmet } from "react-helmet";

// Components //
import AboutTeam from "../../components/about/AboutTeam/AboutTeam";
import AboutAdvisory from "../../components/about/AboutAdvisory/AboutAdvisory";
import AboutHero from "../../components/about/AboutHero/AboutHero";
import AboutPrayerPartner from "../../components/about/AboutPrayerPartner/AboutPrayerPartner";
import AboutWhyWeCharge from "../../components/about/AboutWhyWeCharge/AboutWhyWeCharge";
import AboutValues from "../../components/about/AboutValues/AboutValues";

// Assets //
import AboutHeroBG from "../../global-assets/assets/aboutherobg.webp";

const AboutWhoWeAre = () => {
  useEffect(() => {
    const cookiesAvailable = () => {
      try {
        localStorage.setItem("test", "test");
        localStorage.removeItem("test");
        return true;
      } catch (e) {
        return false;
      }
    };

    if (cookiesAvailable() && process.env.REACT_APP_ENVIRONMENT === "prod") {
      const script = document.createElement("script");
      script.src =
        "https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=STmu7s";
      script.async = true;
      script.type = "text/javascript";
      document.body.appendChild(script);

      return () => {
        if (document.body.contains(script)) {
          document.body.removeChild(script);
        }
      };
    }
  }, []);

  return (
    <div id="test_data_about_page" className="min-h-screen">
      <Helmet>
        <title>About Us | TruPlay Games</title>
        <meta
          name="description"
          content="Welcome to TruPlay, where we merge faith with fun to create a world-class entertainment platform that nurtures hope and biblical truth for kids."
        />
        <link rel="canonical" href="https://www.truplaygames.com/about" />
      </Helmet>
      <div
        className={
          "absolute bottom-0 left-0 right-0 top-0 -z-[1] opacity-50 sm:opacity-60" +
          " bg-[radial-gradient(circle_at_90%_7%,#652c80_0%,transparent_5%),radial-gradient(circle_at_10%_3%,#20568b_0%,transparent_10%)]" +
          " sm:bg-[radial-gradient(circle_at_90%_10%,#652c80_0%,transparent_15%),radial-gradient(circle_at_10%_5%,#20568b_0%,transparent_15%)]"
        }
      />
      <div className="relative overflow-hidden">
        <div className="mx-auto flex max-w-[1440px] flex-col justify-end gap-24 px-5 py-12 pt-24 sm:px-6 md:px-7 lg:gap-40 lg:px-12 lg:pt-32">
          <AboutHero />
          <AboutWhyWeCharge />
          <AboutValues />
        </div>
        <div className="absolute bottom-0 left-0 right-0 top-0 -z-[3]">
          <div className="absolute bottom-0 left-0 right-0 top-0 -z-[3] bg-[linear-gradient(rgba(0,32,63,0.9),#00203f)]" />
          <img
            src={AboutHeroBG}
            loading="eager"
            alt=""
            className="relative -z-[5] h-full w-full object-cover"
          />
        </div>
      </div>
      <AboutTeam />
      <AboutAdvisory />
      <AboutPrayerPartner />
    </div>
  );
};

export default AboutWhoWeAre;
