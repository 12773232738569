// Assets //
import RhymTrain from "./assets/Rhym-train.webp";
import Lms from "./assets/LMS.webp";

// Components //
import CtaImageCard from "../common/CtaImageCard";
import SectionHeader from "../common/SectionHeader";

const MoreGames = () => {
  const openKickstarterPage = () => {
    window.open(
      "https://www.kickstarter.com/projects/truplaygames/truplay-bible-gaming-app-for-children?ref=w6gohd",
      "_blank"
    );
  };

  return (
    <div className="flex w-full flex-col items-center gap-6 lg:gap-20">
      <div className="text-transparent relative flex items-center justify-center bg-gradient_text bg-clip-text">
        <SectionHeader
          headerType="h2"
          headerText="More Games in Development"
          starLocation="right"
        />
      </div>

      <div className="flex w-full flex-col gap-8 md:gap-8">
        <CtaImageCard
          buttonId="games_more_reserve_my_spot_btn"
          image={Lms}
          title="Lucas Mission Starhope"
          description="Lucas tragically lost his twin brother and is on a mission to find him. Helped by his friend Noah, Lucas will begin to discover what it means to pray and have faith. This game will also depict what it looks like to be a faithful friend to someone who finds faith hard during difficult times."
          buttonText="Visit Kickstarter Page"
          onButtonClick={openKickstarterPage}
        />
        <CtaImageCard
          buttonId="games_more_reserve_my_spot_btn"
          image={RhymTrain}
          title="Rhym Train"
          description="Choose your favorite character in this multiplayer game. Compete with others, help others, or just enjoy making your train as long as you can! The game will share wisdom verses from the book of Proverbs tied to various in-game events. “Run to win!” (1 Corinthians 9:24 NLT)"
          buttonText="Visit Kickstarter Page"
          onButtonClick={openKickstarterPage}
        />
      </div>
    </div>
  );
};

export default MoreGames;
