import SectionHeader from "../common/SectionHeader";
import ThreeBlocksSection from "../common/ThreeBlocksSection";

const blocks = [
  {
    title: "Kids Need Faith",
    body: "Only 31% of kids believe in God. Are we surprised when most of what they consume lacks positive, hopeful, or faith-based messages.",
  },
  {
    title: "Digital Influence is Real",
    body: "Kids average 7 hours a day on screens - let's make some of that time worthwhile!",
  },
  {
    title: "Our Kids Deserve Better",
    body: "Games shouldn't expose kids to toxic content, leading to increased anxiety, depression, and a concerning decline in faith.",
  },
];

export default function WhyNow() {
  return (
    <div className="flex flex-col gap-6 lg:gap-20">
      <SectionHeader
        headerType="h2"
        headerText="Why Now?"
        subtext="We know the stress of trying to find safe, fun, and uplifting games for our families… so we started TruPlay Games to make faith-based games we all can trust."
        starLocation="left"
      />
      <ThreeBlocksSection blocks={blocks} />
    </div>
  );
}
