import { useCallback, useContext, useEffect, useState } from "react";
import RegNavUnauth from "../../components/nav/RegNavUnauth/RegNavUnauth";
import BGLandingImage from "../../global-assets/assets/BG_Image_Landing.webp";
import ModalUpgradeArrow from "../../global-assets/assets/Upgrade-modal-arrow.png";
import PlanRadioBtn from "../../components/registration/PlanRadioBtn/PlanRadioBtn";
import ModalContainer from "../../components/common/ModalContainer";
import Button from "../../components/common/Button";
import { Helmet } from "react-helmet";

import ApiInstance from "../../lib/ApiFunction";
import {
  EnvironmentConfigContext,
  SubscriptionPeriod,
} from "../../config/Environment";
import { toast } from "react-toastify";
import Cookies from "js-cookie";
import {
  Context,
  GetOrCreateSessionAuthContext,
  Subscription,
  isAuthenticatedWithValidToken,
  GetFreeTrial,
  GetSubsplash,
  navigateTo,
} from "../../config/UserAuthContext";
import { useFeatureFlags } from "../../config/FeatureFlagContext";
import TagManager from "react-gtm-module";
import {
  GA4AnalyticsContext,
  MixPanelAnalyticsContext,
} from "../../components/analytics/Analytics";
import { DeviceSessionContext } from "../../components/analytics/DeviceContext";
import { ErrorCodeMap } from "../../lib/Constants";
import { InlineErrorMessage } from "../../components/notification/InlineErrorMessage/InlineErrorMessage";
import VideoModal from "../../components/common/VideoModal";
import { InlineInfoMessage } from "../../components/notification/InlineInfoMessage/InlineInfoMessage";
import { PulseLoader } from "react-spinners";
import ThumbWhyWeCharge from "../../global-assets/assets/thumbnail_why_we_charge.webp";
import YesLadderProgressBar from "../../components/registration/YesLadderProgressBar";
import { useRemoteConfig } from "../../config/FBRemoteConfigContext";
import { useLocation } from "react-router";

/**
 * Subscription Page
 * @constructor
 */

interface RequestPayload {
  priceId: string | null | undefined;
  successUrl: string;
  cancelUrl: string;
  promoCode?: string | null | undefined;
  freeTrialDays?: string;
  metadata?: any;
}

export default function PlanSelection() {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const forward: boolean | null = params.get("fwd") === "true" ? true : false;

  const [selectedPlan, setSelectedPlan] = useState<string>("Annual");
  const handlePlanSelection = (plan: string) => {
    setSelectedPlan(plan);
  };
  const [isVideoVisible, setIsVideoVisible] = useState<boolean>();
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const [showSecondModal, setShowSecondModal] = useState(false);

  const analyticsContext = useContext(MixPanelAnalyticsContext);
  const ga4Context = useContext(GA4AnalyticsContext);

  const { StripeCheckout } = ApiInstance();
  const { SubscriptionAPI } = ApiInstance();

  const [deviceCtx] = useState<DeviceSessionContext>(
    new DeviceSessionContext()
  );

  const [subscriptionData, setSubscriptionData] = useState<Subscription>();

  const config = useContext(EnvironmentConfigContext);

  const monthlyPrice: number =
    config?.productConfig.subscriptionProductPrice.get(
      SubscriptionPeriod.Monthly
    ) || 0;
  const annualPrice: number =
    config?.productConfig.subscriptionProductPrice.get(
      SubscriptionPeriod.Annual
    ) || 0;
  const annualDiscountPrice8999: number =
    config?.productConfig.subscriptionProductPrice.get(
      SubscriptionPeriod.AnnualDiscounted8999
    ) || 0;
  const annualDiscountPrice6999: number =
    config?.productConfig.subscriptionProductPrice.get(
      SubscriptionPeriod.AnnualDiscounted6999
    ) || 0;
  const monthlyDiscountPrice: number =
    config?.productConfig.subscriptionProductPrice.get(
      SubscriptionPeriod.MonthlyDiscounted
    ) || 0;
  const annualHoliday: number =
    config?.productConfig.subscriptionProductPrice.get(
      SubscriptionPeriod.Holiday
    ) || 0;
  const monthlyHoliday: number =
    config?.productConfig.subscriptionProductPrice.get(
      SubscriptionPeriod.MonthlyHoliday
    ) || 0;
  const sixMonth: number =
    config?.productConfig.subscriptionProductPrice.get(
      SubscriptionPeriod.SixMonth
    ) || 0;
  const annualTestPrice: number =
    config?.productConfig.subscriptionProductPrice.get(
      SubscriptionPeriod.AnnualTest
    ) || 0;

  const osType = deviceCtx.os.name;

  const userContext = GetOrCreateSessionAuthContext();
  const GlobalUserId = Cookies.get("GlobalUserId");

  const [loading, setLoading] = useState<boolean>(true);
  const [firebaseFlagsLoading, setFirebaseFlagsLoading] =
    useState<boolean>(false);
  const [subError, setSubError] = useState<string | null>(null);
  const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);
  const [stripeLoadError, setStripeLoadError] = useState<string | null>(null);
  const [prevSubscriber, setPrevSubscriber] = useState<boolean>(false);

  const freeTrialCookie: any = GetFreeTrial();
  const subsplash: any = GetSubsplash();

  const featureFlags = useFeatureFlags();
  const FreeTrial30 = featureFlags["2024-04 - 30 Day Free Trial"]?.variationId;
  const [freeTrial30, setFreeTrial30] = useState<boolean>(false);
  const AnnualPriceTest =
    featureFlags["2024-06 - Select Plan Page - Annual Pricing Test ($79.99)"]
      ?.variationId;
  const [annualPriceTest, setAnnualPriceTest] = useState<boolean>(false);
  const [clickId, setClickId] = useState<string | null>(null);

  Cookies.remove(Context.Pk);

  const firebaseFlags = useRemoteConfig();

  if (subscriptionData?.isActive) navigateTo("/account-settings?fwd=true");

  const getSubscription = async () => {
    if (!isAuthenticatedWithValidToken()) {
      navigateTo("/login?fwd=true");
      return;
    }

    const userContext = GetOrCreateSessionAuthContext();
    if (!userContext?.HasActiveSubscription) {
      await SubscriptionAPI.get("")
        .then((res) => {
          setSubscriptionData(res.data);
          if (res.data.isActive) {
            userContext.subscription = res.data;
            navigateTo("/account-settings?fwd=true");
          } else {
            if (forward) {
              setTimeout(() => {
                setLoading(false);
              }, 2000);
            } else {
              setLoading(false);
            }
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            setSubError("You are not authorized to view this page.");
          }
          setLoading(false);
        });
      Cookies.set(Context.UserContext, JSON.stringify(userContext));
    } else {
      setSubscriptionData(userContext?.subscription);
      if (forward) {
        setTimeout(() => {
          setLoading(false);
        }, 3000);
      } else {
        setLoading(false);
      }
    }
  };

  const [originalPrice, setOriginalPrice] = useState<string | number | null>(
    (monthlyPrice * 12).toFixed(2)
  );
  const [currentAnnualPrice, setCurrentAnnualPrice] = useState<string | number>(
    annualPrice
  );
  const [annualMonthlyPrice, setAnnualMonthlyPrice] = useState<string | number>(
    (annualPrice / 12).toFixed(2)
  );
  const [currentMonthlyPrice, setCurrentMonthlyPrice] = useState<
    string | number
  >(monthlyPrice);
  const [featuredText, setFeaturedText] = useState<string | null>("Best Value");
  const [annualPlanSubtext, setAnnualPlanSubtext] = useState<string | null>(
    "billed annually"
  );
  const [monthlyPlanSubtext, setMonthlyPlanSubtext] = useState<string | null>(
    "billed monthly"
  );
  useEffect(() => {
    if (freeTrialCookie) {
      // Modify the above state variables if the free trial cookie is active
    }
  }, [freeTrialCookie]);

  useEffect(() => {
    if (FreeTrial30) {
      switch (FreeTrial30) {
        case "1":
          break;
        case "2":
          setFreeTrial30(true);
          break;
      }
    }
  }, [FreeTrial30]);

  useEffect(() => {
    switch (AnnualPriceTest) {
      case "1":
        setSelectedPlan("Annual");
        break;
      case "2":
        setAnnualPriceTest(true);
        setSelectedPlan("Annual Test");
        break;
    }
  }, [AnnualPriceTest]);

  const handlePlanVariables = useCallback(
    (firebaseFlags: any) => {
      if (firebaseFlags.thanksgivingPriceDiscount2023) {
        // Thanksgiving/Christmas holiday
      }
      if (firebaseFlags.showDiscountedPriceOffer) {
        // Campaign A/B experiments
      }
      if (firebaseFlags.easterDiscount) {
        setOriginalPrice(99.99);
        setCurrentAnnualPrice(annualPrice);
        setFeaturedText("Easter Sale");
        setFirebaseFlagsLoading(false);
      } else if (firebaseFlags.easterDiscount === undefined) {
        setFirebaseFlagsLoading(true);
      } else {
        setFirebaseFlagsLoading(false);
      }
      if (firebaseFlags.springSale) {
        setFeaturedText("Spring Sale");
        setOriginalPrice(155.88);
        setCurrentAnnualPrice(annualPrice);
        setFirebaseFlagsLoading(false);
      } else if (firebaseFlags.springSale === undefined) {
        setFirebaseFlagsLoading(true);
      } else {
        setFirebaseFlagsLoading(false);
      }
    },
    [firebaseFlags]
  );

  useEffect(() => {
    handlePlanVariables(firebaseFlags);
  }, [firebaseFlags]);

  useEffect(() => {
    const fetchData = async () => {
      await getSubscription();
    };

    fetchData();

    TagManager.dataLayer({
      dataLayer: {
        event: "page_view",
        page_name: "accounts: subscription select plan",
        page_type: "accounts",
        os_type: osType,
        custom_user_id: GlobalUserId || undefined,
      },
    });
  }, []);

  useEffect(() => {
    if (typeof window !== "undefined" && window.ire) {
      window.ire("generateClickId", function (clickid: string) {
        setClickId(clickid);
      });
    }
  }, []);

  /**
   * Initiate Stripe checkout process
   * @param stripePlanType
   */

  const startCheckoutProcess = (planType: string) => {
    setLoadingSubmit(true);
    ga4Context.track("enter_stripe_flow", { planType });
    const planToPriceIdMap: Record<string, SubscriptionPeriod> = {
      Monthly: SubscriptionPeriod.Monthly,
      Annual: SubscriptionPeriod.Annual,
      "Annual Test": SubscriptionPeriod.AnnualTest,
      "Six Month": SubscriptionPeriod.SixMonth,
      "Monthly Discounted": SubscriptionPeriod.MonthlyDiscounted,
      "Annual Discounted 89.99": SubscriptionPeriod.AnnualDiscounted8999,
      "Annual Discounted 69.99": SubscriptionPeriod.AnnualDiscounted6999,
      "Annual Holiday": SubscriptionPeriod.Annual,
      "Monthly Holiday": SubscriptionPeriod.Monthly,
    };

    const priceId = config?.productConfig.subscriptionProductId.get(
      planToPriceIdMap[planType]
    );

    const holidayPromoCode = config.couponCodes.holidayPromoCode;
    const freeTrialPromoCode = config.couponCodes.freeTrialPromoCode;
    const addMetadata = (key: any, value: any) => {
      if (value) {
        requestPayload.metadata.push({ Key: key, Value: value });
      }
    };

    let requestPayload: RequestPayload = {
      priceId: priceId,
      successUrl: `${window.location.origin}/payment-completed`,
      cancelUrl: window.location.href,
      metadata: [],
    };

    if (planType === "Annual Holiday") {
      requestPayload = {
        ...requestPayload,
        promoCode: freeTrialCookie ? freeTrialPromoCode : holidayPromoCode,
        ...(freeTrialCookie && { freeTrialDays: "7" }),
      };
    } else if (freeTrialCookie && freeTrial30) {
      requestPayload = {
        ...requestPayload,
        freeTrialDays: "30",
      };
    } else if (freeTrialCookie && !freeTrial30) {
      requestPayload = {
        ...requestPayload,
        freeTrialDays: "7",
      };
    }

    addMetadata("google_client_id", Cookies.get("_ga"));
    addMetadata("affiliate", subsplash ? "subsplash" : null);
    if (clickId) {
      addMetadata("impact_click_id", clickId);
    }

    //@ts-ignore
    if (window["Rewardful"] && window["Rewardful"]["referral"]) {
      //@ts-ignore
      let referralId = window["Rewardful"]["referral"] || "";
      //@ts-ignore
      requestPayload.referralId = referralId;
      analyticsContext.track("Web: Payment-In-Stripe-From-Rewardful", {
        referralId: referralId,
      });
    }

    proceedToCheckout(requestPayload, planType);
  };

  const proceedToCheckout = (
    requestPayload: RequestPayload,
    planType: string
  ) => {
    TagManager.dataLayer({
      dataLayer: {
        event: "plan_type_select",
        plan_type: planType,
        os_type: navigator.userAgent,
        custom_user_id: GlobalUserId || undefined,
      },
    });
    StripeCheckout.post("", requestPayload)
      .then((res) => {
        analyticsContext.track("Web: Payment-In-Stripe-Initiated", {
          plan: planType,
          price_id: requestPayload.priceId || "",
        });
        window.open(res.data.checkoutUrl, "_self");
      })
      .catch((error) => {
        handleCheckoutError(error);
      });
  };

  const handleCheckoutError = (error: any) => {
    let userMessage = "An unexpected error occurred.";
    if (error.response) {
      const statusCode = error.response.data.statusCode;
      const errorMessage =
        ErrorCodeMap[statusCode] || error.response.data.statusMessage;
      userMessage = errorMessage;

      if (statusCode === "AS103") {
        setPrevSubscriber(true);
        Cookies.remove(Context.FreeTrial);
        Cookies.remove(Context.Subsplash);
      }

      if (error.response.status === 401) {
        toast.warn("Session timeout. Navigating back to the Log In page.", {
          position: "top-center",
        });
        setTimeout(() => navigateTo("/login"), 5000);
      }
    }
    setLoadingSubmit(false);
    setStripeLoadError(userMessage);
  };

  const handleModalResponse = (response: string) => {
    if (response === "yes") {
      if (annualPriceTest) {
        startCheckoutProcess("Annual Test");
      } else {
        startCheckoutProcess("Annual");
      }
    } else {
      setShowSecondModal(true);
    }
  };

  const handleSecondModalResponse = (response: string) => {
    response === "yes"
      ? startCheckoutProcess("Six Month")
      : startCheckoutProcess("Monthly");
  };

  const handleClick = (planType: string) => {
    if (selectedPlan === "Monthly") {
      setShowUpgradeModal(true);
    } else {
      startCheckoutProcess(planType);
    }
  };

  if (loading || firebaseFlagsLoading) {
    return (
      <div className="flex min-h-screen items-center justify-center bg-white regmd:bg-indigo-900">
        <div className="hidden regmd:block">
          <PulseLoader color="#fff" />
        </div>
        <div className="regmd:hidden">
          <PulseLoader color="#003E7A" />
        </div>
      </div>
    );
  }

  // TODO: Remove once Firebase token is accepted by Account Service API
  if (subError) {
    return (
      <div className="flex min-h-screen flex-col items-center justify-center">
        <h2 className="text-white">Unauthorized</h2>
        <p className="text-white">{subError}</p>
        <Button
          id="unauth_go_home_btn"
          btnType="secondary"
          onClick={() => navigateTo("/")}
        >
          Go Back Home
        </Button>
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>TruPlay Games: Christian Games, Videos, and Stories</title>
        <meta
          name="description"
          content="Full access to faith-building adventiures, bible stories, Christian games , videos and stories  kids will love and parents can trust."
        />
        <link
          rel="canonical"
          href="https://www.truplaygames.com/select-plan?fwd=true"
        />
      </Helmet>
      <ModalContainer isOpen={showUpgradeModal}>
        <>
          <img
            className="absolute top-[100px] z-10 w-1/5"
            src={ModalUpgradeArrow}
            alt="Purple Upgrade Arrow"
          />
          <div>
            <h5 className="mb-0 text-center text-2xl font-bold text-indigo-500">
              Upgrade to Annual and
            </h5>
            <h2 className="mb-0 text-center text-3xl font-bold text-violet-400">
              SAVE 55%
            </h2>
          </div>
          <div className="mb-4 mt-5 flex justify-around">
            <div className="relative mr-4 w-1/2 rounded-2xl bg-gray-100 px-7 py-4 shadow-md">
              <p className="mb-0 text-left text-sm font-bold text-indigo-300">
                Monthly
              </p>
              <h5 className="whitespace-nowrap text-left text-xl font-bold text-indigo-500">
                $12.99 /mo
              </h5>
              <div className="absolute bottom-0 left-0 w-full rounded-bl-2xl rounded-br-2xl bg-gray-300 py-2 text-center text-sm font-bold text-indigo-500">
                CURRENT PLAN
              </div>
            </div>
            <div className="relative w-1/2 rounded-2xl bg-tangerine-100 px-7 py-4 shadow-md">
              <p className="mb-0 text-left text-sm font-bold text-indigo-300">
                Annual
              </p>
              <h5 className="whitespace-nowrap text-left text-xl font-bold text-indigo-500">
                {annualPriceTest ? "$6.67" : "$5.83"}
              </h5>
              <p className="text-left text-sm font-bold text-indigo-300">
                Billed Yearly
              </p>
              <div className="absolute bottom-0 left-0 w-full rounded-bl-2xl rounded-br-2xl bg-tangerine-500 py-2 text-center text-sm font-bold text-white">
                SAVE 55%
              </div>
            </div>
          </div>
          <div className="mt-4 min-w-full">
            <Button
              id="6month_modal_response1_annual_btn"
              onClick={() => handleModalResponse("yes")}
              loading={loadingSubmit}
              btnType="green"
              className="w-full"
            >
              Yes, I want to save 55%
            </Button>
          </div>
          <div className="no-thanks-button-container mt-5">
            <Button
              id="6month_modal_response1_monthly_btn"
              btnType="tertiaryAlt"
              onClick={() => handleModalResponse("no")}
              className="!font-body"
            >
              No Thanks
            </Button>
          </div>
          {stripeLoadError && !prevSubscriber && (
            <InlineErrorMessage errorMessage={stripeLoadError} />
          )}
          {prevSubscriber && <InlineInfoMessage message={stripeLoadError} />}
        </>
      </ModalContainer>

      <ModalContainer isOpen={showSecondModal}>
        <>
          <div className="mb-3 text-center">
            <h5 className="upgrade-modal-title max-w-sm text-xl font-bold text-indigo-500 lg:text-2xl">
              Not ready to commit to a whole year?
            </h5>
          </div>
          <div className="relative mt-0 min-w-full rounded-3xl bg-tangerine-100 p-7 text-center shadow-md">
            <p className="mb-0 whitespace-nowrap text-lg text-indigo-300">
              How about{" "}
              <span className="text-2xl font-bold text-indigo-500">
                6 months
              </span>{" "}
              at
            </p>
            <h5 className="mb-4 text-3xl font-bold text-indigo-500">
              $6.49 <span className="text-xl">/mo</span>
            </h5>
            <div className="absolute bottom-0 left-0 w-full rounded-b-3xl bg-tangerine-500 py-1 text-center text-base font-bold text-white">
              STILL 50% SAVINGS
            </div>
          </div>
          <div className="mt-4 min-w-full">
            <Button
              id="6month_cta_btn"
              loading={loadingSubmit}
              onClick={() => handleSecondModalResponse("yes")}
              btnType="green"
              className="w-full"
            >
              Let's do 6 Months!
            </Button>
          </div>
          <div className="mt-4 text-center">
            <Button
              id="6month_modal_response2_monthly_btn"
              onClick={() => handleSecondModalResponse("no")}
              btnType="tertiaryAlt"
              className="!font-body"
            >
              Subscribe to Monthly
            </Button>
            <p className="text-lg font-bold text-gray-900">$12.99/mo</p>
          </div>
          {stripeLoadError && !prevSubscriber && (
            <InlineErrorMessage errorMessage={stripeLoadError} />
          )}
          {prevSubscriber && <InlineInfoMessage message={stripeLoadError} />}
        </>
      </ModalContainer>
      <RegNavUnauth />
      <div className="absolute bottom-0 left-0 right-0 top-0 -z-[1] min-h-screen bg-white regmd:hidden" />
      <div className="tru-register-flow-purple-bg absolute bottom-0 left-0 right-0 top-0 -z-[2] hidden opacity-50 regmd:block" />
      <div className="tru-register-flow-blue-bg absolute bottom-0 left-0 right-0 top-0 -z-[2] hidden opacity-40 regmd:block" />
      <div className="tru-register-flow-middle-bg absolute bottom-0 left-0 right-0 top-0 -z-[1] hidden opacity-50 regmd:block" />
      <div
        id="test_data_selectSubscription_page"
        className="regmd:flex regmd:items-center regmd:justify-center regmd:p-44"
      >
        <div className="relative mx-auto block min-h-screen flex-col gap-4 pt-28 regmd:min-h-fit regmd:w-[515px] regmd:rounded-[40px] regmd:bg-white regmd:px-0 regmd:py-8">
          <div className="relative mx-auto mb-4 flex max-w-[450px] flex-col gap-4 px-6 regmd:m-0 regmd:max-w-[515px]">
            <h4 className="mx-auto mb-2 text-center text-3xl text-indigo-500 sm:max-w-[260px]">
              Build Character, Nurture Faith
            </h4>
            <YesLadderProgressBar progress={1} />
          </div>
          <div className="relative mx-auto mt-4 flex max-w-[450px] flex-col gap-4 px-6 regmd:max-w-[515px]">
            <div className="my-2 text-center">
              <p className="m-0">Unlimited access for up to 10 users</p>
            </div>
            <div className="flex flex-col gap-3">
              <PlanRadioBtn
                id="annual_plan_selection_radio"
                planOption={annualPriceTest ? "Annual Test" : "Annual"}
                displayName="Annual"
                price={annualPriceTest ? "6.67" : "5.83"}
                originalPrice={originalPrice || undefined}
                annualPrice={
                  annualPriceTest ? annualTestPrice : annualDiscountPrice6999
                }
                planSubtext={annualPlanSubtext}
                featuredText={featuredText}
                checked={
                  selectedPlan === "Annual" || selectedPlan === "Annual Test"
                }
                selectPlan={handlePlanSelection}
              />
              <PlanRadioBtn
                id="monthly_plan_selection_radio"
                planOption="Monthly"
                displayName="Monthly"
                price={currentMonthlyPrice}
                planSubtext={monthlyPlanSubtext}
                checked={selectedPlan === "Monthly"}
                selectPlan={handlePlanSelection}
              />
            </div>
            {stripeLoadError && !prevSubscriber && (
              <InlineErrorMessage errorMessage={stripeLoadError} />
            )}
            {prevSubscriber && <InlineInfoMessage message={stripeLoadError} />}
            <div>
              <Button
                id="submit_subscription_btn"
                loading={loadingSubmit}
                onClick={() => handleClick(selectedPlan)}
                btnType="green"
                className="w-full"
              >
                {freeTrialCookie
                  ? freeTrial30
                    ? "Start 30 Day FREE Trial"
                    : "Redeem Your Free Week"
                  : "Continue"}
              </Button>
            </div>
            <p className="m-0 text-center font-body font-bold text-gray-900">
              Cancel at anytime for any reason
            </p>
            <Button
              id="why_we_charge_link"
              onClick={() => setIsVideoVisible(true)}
              btnType="tertiaryAlt"
              className="mx-auto !font-body !text-sm font-bold hover:underline"
            >
              Why do we charge for a subscription?
            </Button>
            {isVideoVisible && (
              <VideoModal
                setIsOpen={setIsVideoVisible}
                videoLink="https://truplay-videos.sfo3.cdn.digitaloceanspaces.com/why_we_charge_a_subscription.%20(720p).mp4"
                shareLink="https://vimeo.com/920536083"
                thumbnail={ThumbWhyWeCharge}
              />
            )}
          </div>
        </div>
        <div className="absolute bottom-auto left-0 right-0 top-0 -z-[3] mx-auto max-w-none overflow-hidden regmd:flex">
          <img
            src={BGLandingImage}
            loading="eager"
            sizes="100vw"
            alt=""
            className="relative -z-[1] w-full min-w-[1920px]"
          />
        </div>
      </div>
    </>
  );
}
