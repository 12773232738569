import BlurredSection from "../common/BlurredSection";

export default function CustomerReviewBreak() {
  return (
    <BlurredSection fullWidth>
      <p className="m-0 text-center text-lg text-white lg:text-3xl lg:!leading-normal">
        "A great platform for reaching this generation with the relevance of
        technology by the word of God."
      </p>
      <p className="m-0 text-center text-lg font-normal text-indigo-200 lg:text-2xl lg:font-semibold">
        - Customer Review, May 2024
      </p>
    </BlurredSection>
  );
}
