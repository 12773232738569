interface AfterPurchaseSteps {
  customStep?: JSX.Element;
  step?: string;
  directions?: string | JSX.Element;
}

interface IAfterPurchaseStepsProps {
  steps: AfterPurchaseSteps[];
}

export default function AfterPurchaseSteps({
  steps,
}: IAfterPurchaseStepsProps) {
  return (
    <div className="flex flex-col gap-4">
      {steps.map((step, index) => {
        if (step.customStep) {
          return step.customStep;
        } else {
          return (
            <div
              key={step.step}
              className="relative flex w-full gap-3 rounded-[30px] bg-white/[6%] p-4 text-start shadow-[0px_0px_80px_0px_#001B36] backdrop-blur-[3px] md:gap-6 md:p-8 lg:min-w-[800px]"
            >
              <div className="text-transparent flex min-w-[67px] justify-center bg-gradient_text bg-clip-text font-display text-8xl font-bold lg:min-w-[89px] lg:text-9xl lg:!leading-[0.8]">
                {index + 1}
              </div>
              <div className="flex flex-col gap-2">
                <h6 className="font-display text-2xl font-bold text-white drop-shadow-[0_0_96px_#38a6d5bf] lg:text-3xl">
                  {step.step}
                </h6>
                <p className="m-0 font-body text-base font-normal text-indigo-200 lg:text-xl">
                  {step.directions}
                </p>
              </div>
            </div>
          );
        }
      })}
    </div>
  );
}
